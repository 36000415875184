import axios from "./index";

interface Html {
    html: string;
}

interface Image {
    contentType: string;
    base64: string;
}

export const ContentApi = {
    async help(): Promise<Html> {
        return await axios.get("/api/app/content/help");
    },
    async image(url: string): Promise<Image> {
        return await axios.get(`/api/app/content/image?url=${encodeURIComponent(url)}`);
    },
} as const;
