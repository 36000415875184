import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router"
import { OrderStore } from "@/store/orderstate";
import { Order } from "@/models/ordermodels";
import { OrderService } from "@/services/OrderService";
import GoBack from '../../components/goBack/GoBack.vue'

export default defineComponent({

  components: { GoBack },

  setup() {
    if (!OrderStore.order) {
      throw "Order not found";
    }

    const router = useRouter();
    const order = reactive<Order>(OrderStore.order);
    const offerName = ref<string>(order.offerName);

    const saveOrder = async () => {
      order.offerName = offerName.value;
      await OrderService.updateOrder(order);
      await OrderService.loadOrder(order.id);
      router.push({ name: "order", params: { order: order.id } });
    };

    return {
      order,
      saveOrder,
      offerName
    }
  }
});
