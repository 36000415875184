<template>
  <div class="container-with-side-img">
    <side-image />
    <div class="forgot-password">
      <div class="forgot-password__inner-container">
      <Form @submit="onForgetPassword" v-if="showForm">
        <div class="input__container">
          <label class="input__label input__label--small" for="emailAddress">{{
            $t("ForgotPassword:EmailAddress")
          }}</label>
          <Field
            id="emailAddress"
            name="emailAddress"
            as="input"
            type="email"
            rules="required|email"
            v-model="emailAddress"
          />
          <ErrorMessage name="emailAddress" class="input__error-msg" />
        </div>
        <p>
          <button class="btn__default--primary" type="submit" :disabled="isForgettingPassword" :class="{ btn__disabled: isForgettingPassword }">
            {{ $t("ForgotPassword:ForgotPassword") }}
          </button>
        </p>
      </Form>

      <div v-if="confirmEmail || setPassword">
        <Form v-on="{ submit: confirmEmail ? onConfirmEmail : onSetPassword }" ref="form">
          <div class="input__container">
            <label for="password" class="input__label input__label--small">{{
              $t("ForgotPassword:NewPassword")
            }}</label>
            <Field
              id="password"
              name="password"
              as="input"
              type="password"
              rules="required"
              v-model="password"
            />
            <ErrorMessage name="password" class="input__error-msg" />
          </div>
          <div class="input__container">
            <label
              for="passwordConfirm"
              class="input__label input__label--small"
              >{{ $t("ForgotPassword:NewPasswordConfirm") }}</label
            >
            <Field
              id="passwordConfirm"
              name="passwordConfirm"
              as="input"
              type="password"
              rules="required"
              v-model="passwordConfirm"
            />
            <ErrorMessage name="passwordConfirm" class="input__error-msg" />
          </div>
          <p>
            <button class="btn__default--primary" type="submit" :disabled="isChangingPassword" :class="{ btn__disabled: isChangingPassword }">
              {{ $t("ForgotPassword:ChangePassword") }}
            </button>
          </p>
        </Form>
      </div>

      <div class="forgot-password__msg-container" :class="confirmEmail || setPassword ? 'forgot-password__msg-container--two-inputs' : ''">
        <p class="msg__error" v-if="errorMessage.length > 0">
          {{ errorMessage }}
        </p>
        <p class="msg__success" v-if="successMessage.length > 0">
          {{ successMessage }}
        </p>
      </div>
    </div>
    </div>
  </div>
</template>

<script src="./ForgotPassword.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./ForgotPassword.scss";
</style>