<template>
  <div class="order-sidebar__container" :class="{hideOnMobile: hideOnMobile}">
    <div>
      <go-back
        :title="order.offerName"
        route-name="orders"
        :showSettings="true" @click="goBack"
      />
    </div>
    <div class="order-sidebar__tab-container">
      <div>
        <template v-for="item in menuItems" :key="item.type">
          <button class="tab__title"
            v-bind:class="{ active: selectedItem === item.type }"
            v-on:click="select(item.type)">
            {{ item.text }}
          </button>
        </template>
      </div>
      <div v-show="selectedItem === menuItemType.Markings || editMode">
        <button class="tab__title edit-mode__btn" @click="toggleEdit">
          <span v-if="editMode">{{$t('OrderSideBar:Cancel')}}</span>
          <span v-if="!editMode">{{$t('OrderSideBar:Change')}}</span>
        </button>
      </div>
    </div>
    <hr />
    <div class="order-sidebar__folder-tree" v-if="order.folder">
      <div class="overlay" :class="{active: showOverlay}" ></div>
      <div v-show="selectedItem === menuItemType.Markings">
        <ul class="order-sidebar__list">
          <folder-tree-view :folder="order.folder" :folder-selection-enabled="true" :marking-route="'marking'" />
        </ul>
      </div>
      <div v-if="selectedItem === menuItemType.Fabrics">
        <fabric-tree-view />
      </div>
      <div v-show="selectedItem === menuItemType.Images">
        <ul class="order-sidebar__list">
          <folder-tree-view :folder="order.folder" :folder-selection-enabled="false" :marking-route="'marking-image-list'" />
        </ul>
      </div>
    </div>
    <div class="action-bar__outer-container" :class="{'edit-mode__outer-container': editMode}">
      <div v-if="editMode" class="action-bar__inner-container edit-mode__inner-container">
        <button type="button" class="tab__title tab__btn" @click="duplicateSelected" :disabled="disabledEditMode" :class="{'tab--disabled': disabledEditMode}" :title="$t('OrderSideBar:CanOnlySelectOneItem')">{{$t('OrderSideBar:Duplicate')}}</button>
        <button type="button" class="tab__title tab__btn" @click="deleteSelected" :disabled="disabledDeleteBtn" :class="{'tab--disabled': disabledDeleteBtn}" >{{$t('OrderSideBar:Delete')}}</button>
        <button type="button" class="tab__title tab__btn" @click="changeSelected" :disabled="disabledEditMode" :class="{'tab--disabled': disabledEditMode}" :title="$t('OrderSideBar:CanOnlySelectOneItem')">{{$t('OrderSideBar:ChangeName')}}</button>
        <p v-if="showDuplicateMessage">{{$t("OrderSideBar:DuplicateError")}}</p>
      </div>
      <div v-if="!editMode" class="action-bar__inner-container">
        <div
          class="action-bar__price-container"
          v-bind:style="{ visibility: order.orderTotalExclVat.value > 0 ? 'visible' : 'hidden' }"
        >
          <span>{{$t('OrderSideBar:Total')}} </span>
          <span class="action-bar__price">{{order.orderTotalExclVat.formatted}}</span>
        </div>
        <folder-context-menu v-if="selectedItem === menuItemType.Markings" @context-click="handleContextClick" />
        <marking-context-menu v-if="selectedItem === menuItemType.Images || selectedItem === menuItemType.Markings" @context-click="handleContextClick" />
        <!-- TODO: Change routing -->
        <div v-if="orderPreviewIsEnabled">
          <router-link :to="{name: getPreviewOrderRouteName}">
            <span class="action-bar__order-link">{{$t('OrderSideBar:Order')}}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./OrderSidebar.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./OrderSidebar.scss";
</style>
