import { Options, Vue } from "vue-class-component";
import { AccountService } from "@/axios/accountservice";
import { AccountConfirm, AccountForgotOrCreate } from "@/models/accountmodels";
import qs from "qs";
import { UserFriendlyError } from "@/models/errormodels";
import SideImage from "@/components/sideImage/SideImage.vue";

@Options({
  components:{
    SideImage
  }
})
export default class ForgotPassword extends Vue {
  protected emailAddress = "";
  protected password = "";
  protected passwordConfirm = "";
  protected errorMessage = "";
  protected successMessage = "";
  protected setPassword = false;
  protected confirmEmail = false;
  protected isForgettingPassword = false;
  protected isChangingPassword = false;

  public mounted(): void {
    const queryString = qs.parse(location.search.substring(1));

    if (queryString.action && queryString.personId) {
      this.setPassword = queryString.action === "password";
      this.confirmEmail = queryString.action === "confirm";
    }
  }

  protected get showForm(): boolean {
    return !this.setPassword && !this.confirmEmail;
  }

  protected onForgetPassword(): void {
    this.errorMessage = "";
    this.successMessage = "";
    this.isForgettingPassword = true;
    const model: AccountForgotOrCreate = {
      emailAddress: this.emailAddress,
      localeAndPath: window.location.pathname
    };
    AccountService.forgotPassword(model).then((data) => {
      if (data.hasAccount) {
        this.successMessage = this.$t("ForgotPassword:EmailSent");
        this.isForgettingPassword = false;
      } else {
        this.onCreateAccount();
      }
    }, (details: UserFriendlyError) => {
      this.errorMessage = details.message;
      this.isForgettingPassword = false;
    });
  }

  protected onCreateAccount(): void {
    this.errorMessage = "";
    this.successMessage = "";
    const model: AccountForgotOrCreate = {
      emailAddress: this.emailAddress,
      localeAndPath: window.location.pathname
    };
    AccountService.createAccount(model).then(() => {
      this.successMessage = this.$t("ForgotPassword:AccountCreatedEmailSent");
      this.isForgettingPassword = false;
    }, (details: UserFriendlyError) => {
      this.errorMessage = details.message;
      this.isForgettingPassword = false;
    });
  }

  protected onConfirmEmail(): void {
    this.errorMessage = "";
    this.successMessage = "";
    this.isChangingPassword = true;
    const queryString = qs.parse(location.search.substring(1));
    const model: AccountConfirm = {
      personId: queryString.personId as string,
      password: this.password,
      passwordConfirm: this.passwordConfirm
    };
    AccountService.confirmEmail(model).then(() => {
      this.password = "";
      this.passwordConfirm = "";
      this.successMessage = this.$t("ForgotPassword:EmailPasswordConfirmed");
      
      setTimeout(() => {
        this.isChangingPassword = false;
        this.$router.push({ name: "login"}); 
      }, 3000);
    }, (details: UserFriendlyError) => {
      this.errorMessage = details.message;
      this.isChangingPassword = false;
    });
  }

  protected onSetPassword(): void {
    this.errorMessage = "";
    this.successMessage = "";
    this.isChangingPassword = true;
    const queryString = qs.parse(location.search.substring(1));
    const model: AccountConfirm = {
      personId: queryString.personId as string,
      password: this.password,
      passwordConfirm: this.passwordConfirm
    };
    AccountService.setPassword(model).then(() => {
      this.password = "";
      this.passwordConfirm = "";
      (this.$refs.form as HTMLFormElement).resetForm();
      this.successMessage = this.$t("ForgotPassword:PasswordConfirmed");
      
      setTimeout(() => {
        this.$router.push({ name: "login"}); 
        this.isChangingPassword = false;
      }, 3000);
    }, (details: UserFriendlyError) => {
      this.errorMessage = details.message;
      this.isChangingPassword = false;
    });
  }
}