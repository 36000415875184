import { Folder } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import { computed, defineComponent } from "vue";
import FolderTile from "@/components/FolderTile.vue";
import MarkingTile from "@/components/MarkingTile.vue";

export default defineComponent({

    name: "FolderView",

    components: {
        FolderTile,
        MarkingTile
    },

    setup() {
        const folder = computed(() => OrderStore.folder as Folder);
        const folderSize = computed(() => folder.value.folders.length + folder.value.markings.length);
    
        return {
            folder,
            folderSize,
        }
    }
});
