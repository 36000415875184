import { computed, defineComponent, ref, watch } from "vue";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { UIStore } from "@/store/ui";

export default defineComponent({
    name: "Backdrop",
    created() {
        if (process.env.NODE_ENV === "development") {
            window.addEventListener("keypress", (event: KeyboardEvent) => {
                if (event.target === document.body && event.shiftKey && event.code === "KeyL") {
                    UIStore.setLoading(!UIStore.loading);
                }
            })
        }
    },
    setup() {
        const backdrop = ref<HTMLElement | null>(null);
        const enabled = computed<boolean>(() => UIStore.loading);
        watch(enabled, (enabled: boolean) => {
            (enabled ? disableBodyScroll : enableBodyScroll)(backdrop.value as HTMLElement);
        });
        return {
            backdrop,
            enabled,
        };
    },
});
