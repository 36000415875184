import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2";
import { MarkingImage } from "@/models/ordermodels";
import { OrderService } from "@/services/OrderService";
import Image from "@/components/Image.vue";

export default defineComponent({
    components: {
        Image
    },
    props: {
        image: {
            type: Object as () => MarkingImage,
            required: true
        }
    },
    emits: ["update", "delete", "close"],
    setup(props, { emit }) {
        const translate = useI18n().t;
        const editing = ref(false);
        const draft = ref({ ...props.image });

        function edit() {
            editing.value = !editing.value;
        }

        function cancel() {
            draft.value = { ...props.image };
            editing.value = false;
        }

        function close() {
            emit("close");
        }

        async function saveImage() {
            try {
                await OrderService.updateMarkingImage(draft.value);
                emit("update", draft.value);
            } catch (error) {
                console.error(error);
                emit("update");
            } finally {
                editing.value = false;
            }
        };

        async function deleteImage() {
            try {
                editing.value = false;
                const deletionRequest = await Swal.fire({
                    title: translate("MarkingImageUpdate:DeleteImageConfirmTitle"),
                    html: translate("MarkingImageUpdate:DeleteImageConfirmBody"),
                    showCancelButton: true,
                    focusCancel: true,
                    confirmButtonText: translate("MarkingImageUpdate:DeleteImageConfirmButton"),
                    confirmButtonAriaLabel: translate("MarkingImageUpdate:DeleteImageConfirmButton"),
                    cancelButtonText: translate("MarkingImageUpdate:DeleteImageConfirmCancelButton"),
                    cancelButtonAriaLabel: translate("MarkingImageUpdate:DeleteImageConfirmCancelButton")
                });
                if (deletionRequest.isConfirmed) {
                    await OrderService.deleteMarkingImage(props.image.id as string);
                    emit("delete", draft.value.id as string);
                }
            } catch (error) {
                console.error(error);
                emit("delete");
            } finally {
                editing.value = false;
            }
        }

        watch(() => props.image, (image) => {
            draft.value = { ...image };
        });

        return {
            draft: draft.value,
            edit,
            cancel,
            save: saveImage,
            deleteImage,
            close,
            editing,
        }
    }
});
