<template>
  <div class="container-with-side-img">
    <side-image />
    <div class="change-password__outer-container">
      <go-back :title="$t('Manage:ChangeAccountDetailsHeader')" route-name="account" />

      <div class="change-password__inner-container content__container">
        <header class="change-password__header">
          {{ $t("Manage:ChangeAccountDetailsHeader") }}
        </header>
        <Form @submit="onChangePassword" v-if="currentUser" ref="form">
          <div class="input__container change-password__input">
            <label
              for="currentPassword"
              class="input__label--small input__label input__required"
              >{{ $t("Manage:CurrentPassword") }}</label
            >
            <Field
              id="currentPassword"
              name="currentPassword"
              as="input"
              type="password"
              :label="$t('Manage:CurrentPassword')"
              rules="required"
              v-model="currentPassword"
            />
            <ErrorMessage class="input__error-msg" name="currentPassword" />
          </div>
          <div class="input__container change-password__input">
            <label
              for="password"
              class="input__label--small input__label input__required"
              >{{ $t("Manage:NewPassword") }}</label
            >
            <Field
              id="password"
              name="password"
              as="input"
              type="password"
              :label="$t('Manage:NewPassword')"
              rules="required"
              v-model="password"
            />
            <ErrorMessage name="password" class="input__error-msg" />
          </div>
          <div class="input__container change-password__input">
            <label
              for="passwordConfirm"
              class="input__label--small input__label input__required"
              >{{ $t("Manage:NewPasswordConfirm") }}</label
            >
            <Field
              id="passwordConfirm"
              name="passwordConfirm"
              as="input"
              type="password"
              :label="$t('Manage:NewPasswordConfirm')"
              rules="required"
              v-model="passwordConfirm"
            />
            <ErrorMessage class="input__error-msg" name="passwordConfirm" />
          </div>
          <p>
            <button type="submit" class="btn__default--primary">
              {{ $t("Manage:ChangePassword") }}
            </button>
          </p>
          <p></p>
        </Form>
        <p class="msg__error" v-if="errorMessage.length > 0">
          {{ errorMessage }}
        </p>
        <p class="msg__success" v-if="successMessage.length > 0">
          {{ successMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./ChangePassword.ts" lang="ts">
</script>

<style lang="scss" scoped>
@import "./ChangePassword.scss";
</style>
