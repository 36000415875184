import { Vue } from "vue-class-component";
import { AccountStore } from "@/store/accountstate";
import { AccountService } from "@/axios/accountservice";
import { AccountToken, Token } from "@/models/accountmodels";

export default class ChooseApplication extends Vue {

    onClickRedirectSven(): void {
        const internalSales = AccountStore.currentUser?.internalSalesSven;
        if (internalSales) {
            this.$router.push({ name: 'searchorders' });
        } else {
            this.$router.push({ name: 'orders' });
        }
    }

    protected get currentToken(): Token | null {
        return AccountStore.currentToken;
    }

    handleLogout(): void {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("currentToken");
    }

    async onClickRedirectAcoustic() : Promise<void> {
        try {
            const accountToken: AccountToken = {
                refreshToken: this.currentToken!.refreshToken 
            };
            const redirectUrl = await AccountService.acousticRedirectLink(accountToken);
            this.handleLogout();
            window.location.href = redirectUrl;
        } catch (error) {
            console.error('Error i acousticRedirectLink :', error);
            this.$router.push({ name: 'login' });
        }
    }
}
