<template>
  <div class="container-with-two-img">
    <div class="dual-img__left-container">
      <div class="text-and-button__upper">
        <h2 class="header">{{ $t("ChooseApp:SvenText") }}</h2>
        <button @click="onClickRedirectSven" class="btn__default--primary">
          {{ $t("ChooseApp:ToSven") }}
        </button>
      </div>
    </div>
    <div class="dual-img__right-container">
      <div class="text-and-button__lower">
        <h2 class="header">{{ $t("ChooseApp:AcousticText") }}</h2>
          <button @click="onClickRedirectAcoustic" class="btn__default--primary" >
            {{ $t("ChooseApp:ToAcoustic") }}
          </button> 
      </div>
    </div>
  </div>
</template>

<script src="./ChooseApp.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./ChooseApp.scss";
</style>
