import { AccountService } from "@/axios/accountservice";
import { AccountPassword, CurrentUser, Token } from "@/models/accountmodels";
import { UserFriendlyError } from "@/models/errormodels";
import { AccountStore } from "@/store/accountstate";
import { Options, Vue } from "vue-class-component";
import SideImage from '@/components/sideImage/SideImage.vue';
import GoBack from '@/components/goBack/GoBack.vue';

@Options({
    name:'ChangePassword',
    components:{
        SideImage,
        GoBack
    }
})

export default class ChangePassword extends Vue{
    protected password = "";
    protected passwordConfirm = "";
    protected currentPassword = "";
    protected errorMessage = "";
    protected successMessage = "";

    protected get currentUser(): CurrentUser | null {
        return AccountStore.currentUser;
      }
      protected set currentUser(user: CurrentUser | null) {
        AccountStore.saveUser(user);
      }
      protected get currentToken(): Token | null {
        return AccountStore.currentToken;
      }
      protected set currentToken(token: Token | null) {
        AccountStore.saveToken(token);
      }
    
      protected onChangePassword(): void {
        this.errorMessage = "";
        this.successMessage = "";
        const model: AccountPassword = {
          currentPassword: this.currentPassword,
          password: this.password,
          passwordConfirm: this.passwordConfirm
        };
        AccountService.changePassword(model).then(() => {
          this.successMessage = this.$t("Manage:PasswordChanged");

          this.currentPassword = "";
          this.password = "";
          this.passwordConfirm = "";
          
          (this.$refs.form as HTMLFormElement).resetForm();
        }, (details: UserFriendlyError) => {
          if (details.status === 302) {
            this.errorMessage = this.$t("Manage:Unauthorized");
          } else {
            this.errorMessage = details.message;
          }
        });
      }


}
