<template>
    <div class="folder-view">
        <h1 class="folder-view__header">{{ folder.name }}</h1>
        <span class="folder-view__nr-of-objects">{{ folderSize > 0 ? folderSize + ` ${$t('FolderTile:FolderObjects')}` : $t('FolderTile:EmptyFolder') }}</span>
        <div v-if="folder.folders.length || folder.markings.length" class="folder-view__content" >
            <folder-tile
                v-for="f in folder.folders"
                :key="f.id"
                :folder="f"
            />
            <marking-tile
                v-for="m in folder.markings"
                :key="m.id"
                :marking="m"
            />
        </div>
    </div>
</template>

<script src="./FolderView.ts" lang="ts"></script>

<style lang="scss" scoped>
@import 'FolderView.scss';
</style>
