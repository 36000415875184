import { getStoreBuilder, BareActionContext } from "vuex-typex";
import { RootState } from "./index";
import { CurrentUser, Token } from "@/models/accountmodels";
import { getSessionStorage, setSessionStorage } from "./storage";

export interface AccountState {
  currentUser: CurrentUser | null;
  currentToken: Token | null;
}

const initialState: AccountState = {
  currentUser: null,
  currentToken: null
}

const store = getStoreBuilder<RootState>().module("account", initialState);

// private getters
function getToken(state: AccountState): Token | null {
  return state.currentToken || getSessionStorage<Token>("currentToken");
}
function getUser(state: AccountState): CurrentUser | null {
  return state.currentUser || getSessionStorage<CurrentUser>("currentUser");
}

// getters
const getStateToken = store.read(getToken, "getToken");
const getStateUser = store.read(getUser, "getUser");

// mutations
function mutateToken(state: AccountState, currentToken: Token | null): Promise<void> {
  return new Promise((resolve) => {
    setSessionStorage<Token>("currentToken", currentToken);
    state.currentToken = currentToken;
    resolve();
  });
}
function mutateUser(state: AccountState, currentUser: CurrentUser | null): Promise<void> {
  return new Promise((resolve) => {
    setSessionStorage<CurrentUser>("currentUser", currentUser);
    state.currentUser = currentUser;
    resolve();
  });
}

// actions
async function saveToken(context: BareActionContext<AccountState, RootState>, currentToken: Token | null): Promise<void> {
  return await mutateToken(context.state, currentToken);
}
async function saveUser(context: BareActionContext<AccountState, RootState>, currentUser: CurrentUser | null): Promise<void> {
  return await mutateUser(context.state, currentUser);
}
async function clearState(context: BareActionContext<AccountState, RootState>): Promise<void> {
  const token = saveToken(context, null);
  const user = saveUser(context, null);
  await Promise.all([token, user]);
}

// export store
export const AccountStore = {
  get currentToken(): Token | null { return getStateToken(); },
  get currentUser(): CurrentUser | null { return getStateUser(); },
  saveToken: store.dispatch(saveToken, "saveToken"),
  saveUser: store.dispatch(saveUser, "saveUser"),
  clearState: store.dispatch(clearState, "clearState")
};
