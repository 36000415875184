import { Options, Vue } from "vue-class-component";
import { OrderService } from "@/axios/orderservice";
import { Order, OrderStatus } from "@/models/ordermodels";
import SideImage from "@/components/sideImage/SideImage.vue";
import { AccountStore } from "@/store/accountstate";
import { CurrentUser } from "@/models/accountmodels";
import Swal from "sweetalert2";

@Options({
    name: "OrderList",
    components: {
        SideImage
    }
})
export default class OrderList extends Vue {
    protected readonly orderStatuses: OrderStatus[] = [
        OrderStatus.Initiated,
        OrderStatus.OfferSentToCustomer,
        OrderStatus.OrderPlaced
    ];
    protected readonly labels: Map<OrderStatus, string> = new Map([
        [OrderStatus.Initiated, "Offers:TabLabelOpenOffers"],
        [OrderStatus.OfferSentToCustomer, "Offers:TabLabelSentToCustomerOffers"],
        [OrderStatus.OrderPlaced, "Offers:TabLabelClosedOffers"],
    ]);
    protected readonly orders: Map<OrderStatus, Order[]> = new Map();
    protected activeStatus: OrderStatus = OrderStatus.Initiated;
    protected ordersForDelete: Order[] = [];
    protected isDeleteMode: boolean = false;
    protected selectedOrdersToDelete: string[] = [];

    created(): void {
        this.loadOrders();
    }

    protected getOrdersForDelete(): Order[] {
        const toDelete: Order[] = [];
        this.orders.forEach((value: Order[], key: OrderStatus) => {
            if (key === OrderStatus.Initiated || key === OrderStatus.OfferSentToCustomer) {
                const ordersOfTheSameUser = value.filter(x => x.userEmailAddress.toLowerCase() === this.currentUser.email.toLowerCase());
                toDelete.push(...ordersOfTheSameUser);
            }
        });
        return toDelete.sort((a, b) => b.offerNumber - a.offerNumber);
    }

    protected enableDeleteOrderButton(): boolean {
        return this.selectedOrdersToDelete.length > 0;
    }

    protected loadOrdersForDelete(): void {
        this.isDeleteMode = true;
        this.selectedOrdersToDelete = [];
        this.ordersForDelete = this.getOrdersForDelete();
    }

    protected async ordersDeleteConfirm(): Promise<void> {
        const response = await Swal.fire({
            title: this.$t("Offers:DeleteConfirmTitle"),
            html: this.$t("Offers:DeleteConfirmBody"),
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: this.$t("Offers:DeleteConfirmButtonText"),
            confirmButtonAriaLabel: this.$t("Offers:DeleteConfirmButtonText"),
            cancelButtonText: this.$t("Offers:DeleteCancelButtonText"),
            cancelButtonAriaLabel: this.$t("Offers:DeleteCancelButtonText")
        });
        if (response.isConfirmed) {
            await OrderService.deleteOrders(this.selectedOrdersToDelete, this.currentUser.email);
            this.loadOrders();
            this.selectedOrdersToDelete = [];
            this.ordersForDelete = [];
            this.isDeleteMode = false;
        }
    }

    protected ordersDeleteCancel(): void {
        this.isDeleteMode = false;
    }

    protected isActive(status: OrderStatus): boolean {
        return this.activeStatus === status;
    }

    protected activate(status: OrderStatus): void {
        this.activeStatus = status;
    }

    protected getRouteByStatus(status: OrderStatus): string {
        if (status === OrderStatus.Initiated) {
            return "order";
        } else {
            return "previewOrderFinished";
        }
    }
    protected get currentUser(): CurrentUser {
        return AccountStore.currentUser as CurrentUser;
    }
    protected getUserOrderSharedText(userEmailAddress: string): string {
        return userEmailAddress !== this.currentUser.email ? "  (" + userEmailAddress + ")" : "";
    }

    protected loadOrders(): void {
        this.orders.clear();
        OrderService.getOrdersByStatus().then((data) => {
            Object.entries(data).forEach(([key, value]) => {
                this.orders.set(parseInt(key), value);
            })
        });
    }
};
