export function getLocalStorage<T>(key: string): T | null {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) as T : null;
};

export function getSessionStorage<T>(key: string): T | null {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) as T : null;
}

export function setLocalStorage<T>(key: string, value: T | null): void {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export function setSessionStorage<T>(key: string, value: T | null): void {
  if (value === null) {
    sessionStorage.removeItem(key);
  } else {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};