<template>
  <ul v-if="order" class="folder-tree fabric-viewer">
    <li
      class="folder-tree__list"
      v-for="fabric in fabricModels"
      :key="fabric.variantId"
    >
      <div class="folder-tree__item folder-tree__root">
        <router-link
          class="folder-tree__link"
          :to="{
            name: 'edit-marking-fabric',
            params: {
              order: order.id,
              product: fabric.productId,
              variant: fabric.variantId,
            },
          }"
        >
          <img
            class="custom-svgs folder-tree__link-icon"
            :src="fabric.variantImage"
            :alt="fabric.variantName"
          />
          {{ fabric.variantName }}
        </router-link>
      </div>
      <ul
        class="folder-tree__markings"
        v-for="line in fabric.markings"
        :key="line.markingId"
      >
        <li>
          <router-link
            class="folder-tree__link fabric-viewer__marking-item"
            :to="{
              name: 'marking',
              params: { order: order.id, marking: line.markingId, step: 3 },
            }"
          >
            <div class="">
              <div class="fabric-viewer__marking-info">
                <img
                  src="../assets/icons/marking.svg"
                  class="custom-svgs fabric-viewer__tag-icon"
                  alt=""
                />
                {{ line.folderName }}
                <img
                  src="../assets/icons/chevron-right.svg"
                  class="custom-svgs fabric-viewer__chevron-icon"
                  alt=""
                />
                {{ line.markingName }}
              </div>
              <div class="fabric-viewer__marking-details">
                {{ getSewingTypeName(line.curtainData.sewingType) }}
                <template v-if="showSimpleConfiguration(line.markingId)">
                  <span
                    v-for="drop in line.curtainData.curtainDrops"
                    :key="drop.finishedWidthPerCurtainDrop"
                  >
                    {{ $t("ConfiguratorEditor:OutputWidthLabel") }}
                    {{
                      $filters.roundDecimals(
                        drop.finishedWidthPerCurtainDrop
                      )
                    }}
                    x
                    {{ $t("ConfiguratorEditor:OutputHeightLabel") }}
                    {{
                      $filters.roundDecimals(
                        drop.finishedHeightPerCurtainDrop
                      )
                    }}
                    {{ drop.quantity }} {{ $t("Marking:PiecesShort") }}
                  </span>
                </template>
                <template v-if="showCoverwallConfiguration(line.markingId)">
                  {{ $t("ConfiguratorEditor:OutputWidthLabel") }}
                  {{
                    $filters.roundDecimals(
                      line.curtainData.totalFinishedWidth
                    )
                  }}
                  x
                  {{ $t("ConfiguratorEditor:OutputHeightLabel") }}
                  {{
                    $filters.roundDecimals(line.curtainData.finishedHeight)
                  }} {{ line.markingQuantity }} {{ $t("Marking:PiecesShort") }}
                </template>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script src="./FabricTreeView.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./FabricTreeView.scss";
</style>
