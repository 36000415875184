import axios from "./index";
import { CreateMarkingImage } from "@/models/imagemodels";
import { MarkingClient } from "./markingclient";

export const ImageService = {
    get imageBaseUrl() {
        return process.env.VUE_APP_API_BASE_URL + "/api/app/image/";
    },
    async createMarkingImage(image: CreateMarkingImage): Promise<void> {
        const data = new FormData();
        data.append("file", image.file);
        data.append("thumbnail", image.createThumbnail.toString());
        data.append("name", image.name);
        data.append("note", image.note);
        data.append("marking", image.markingId);
        await MarkingClient.createImage(data);
    },
    async deleteImages(fileIds: string[]): Promise<void> {
        const parameters = fileIds.map(x => `imageIds=${x}`).join("&");
        return await axios.delete('/api/app/image?' + parameters);
    }
} as const;
