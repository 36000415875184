<template>
  <div class="create-marking__container container-with-tree">
    <div class="create-marking__preview-container">
      <router-link
        :to="{
          name: 'marking-image-list',
          params: { order: order.id, marking: marking.id },
        }"
        class="context-menu__option-link"
      >
        <button class="btn__round create-marking__preview-btn" type="button">
          <img src="../../assets/icons/close.svg" class="custom-svgs" alt="x" />
        </button>
      </router-link>
      <div class="create-marking__preview-image icon--camera" />
    </div>
    <div class="create-marking__content-container">
      <h1>{{$t("MarkingImageUpload:Header")}}</h1>
      <form @submit.prevent="saveImage" v-on:keyup.enter="saveImage" ref="form" class="create-marking__form">
        <div class="input__container">
          <label class="input__label--thin"
            >{{ $t("MarkingImageUpload:ImageFileLabel") }}
          </label>
          <input
            type="file"
            @change="onFileChanged($event)"
            accept="image/*"
            capture
          />
        </div>
        <div class="input__container">
          <label class="input__label--thin"
            >{{ $t("MarkingImageUpload:ImageNameLabel") }}
          </label>
          <input type="text" v-model="markingImage.name" />
        </div>
        <div class="input__container">
          <label class="input__label--thin"
            >{{ $t("MarkingImageUpload:ImageNoteLabel") }}
          </label>
          <input type="text" v-model="markingImage.note" />
        </div>
      </form>
      <div v-if="message">
        {{ $t(message) }}
      </div>
    </div>
    <div class="wizard-bottom-bar__container create-marking__bottom-bar">
      <form @submit.prevent="saveImage">
        <button class="btn__default--primary">
          {{ $t("MarkingImageUpload:ButtonLabel") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script src="./CreateMarkingImage.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./../folders/CreateMarking.scss";
</style>
