<template>
  <div class="variant-list">
    <div
      v-for="variant in variants"
      :key="variant.productId"
      @click="selectVariant(variant)"
      :class="{ 'variant--active': variant.active }"
      class="variant"
    >
      <div class="spinner" v-if="!isNextButtonEnabled && variant.active">
        <span class="spinner-inner-1"></span>
        <span class="spinner-inner-2"></span>
        <span class="spinner-inner-3">        
          <img class="spinner-inner-3-image" :src="variant.image" :title="variant.name" />  
        </span>
      </div>

      <img :src="variant.image" :title="variant.name" />
      <div class="variant__data">
        <span class="variant__data-color" :class="{'variant__data-color--active': variant.active}">{{ variant.color }}</span> <span class="variant__data-width">{{ variant.width }} cm</span>
      </div>
    </div>
  </div>
</template>

<script src="./VariantSelector.ts" lang="ts"></script>

<style lang="scss" scoped>
@import './VariantSelector.scss';
</style>
