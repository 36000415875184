import { computed, defineComponent } from "vue";
import { Marking, Order } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import MarkingTile from "@/components/MarkingTile.vue";
import { ChangeStore } from "@/store/changestate";

export default defineComponent({

    name: "MarkingTreeView",

    components: {
        MarkingTile,
    },

    props: {
        marking: {
            type: Object as () => Marking,
            required: true
        },
        route: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            openPreview: false
        }
    },

    methods: {
        handleCloseMarkingPreview() {
            this.openPreview = !this.openPreview;
        }
    },

    setup(props) {
      const order = computed<Order>(() => OrderStore.order as Order);
      const editMode = computed<boolean>(() => ChangeStore.editMode);

      const selectForEditing = (e: InputEvent): void => {
        const target = (e.target as HTMLInputElement);
        ChangeStore.saveMarkingState({ id: props.marking.id, checked: target.checked, name: props.marking.name });
      };
      return {
          order: order,
          editMode: editMode,
          selectForEditing: selectForEditing
      };
    }
});
