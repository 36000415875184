<template>
  <router-link
    :to="{
      name: 'folderview',
      params: { order: folder.orderId, folder: folder.id },
    }"
  >
    <li class="tile folder-tile">
      <div class="tile__content-container">
        <div class="folder-tile__name">
          {{ folder.name }}
        </div>
        <div class="folder-tile__folder-size">
          {{ folderSize }}
        </div>
      </div>
      <div class="tile__preview-container folder-tile__preview">
        <svg>
          <use xlink:href="../assets/icons/sprite.svg#folder"></use>
        </svg>
      </div>
    </li>
  </router-link>
</template>

<script src="./FolderTile.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./FolderTile.scss";
</style>
