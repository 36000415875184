import { defineComponent, ref } from "vue";
import { OrderStore } from "@/store/orderstate";
import { Order, OrderStatus } from "@/models/ordermodels";
import { useRouter } from "vue-router";

export default defineComponent({

    created() {
        if (this.order.status !== OrderStatus.OrderPlaced) {
            useRouter().push({ name: "folderview", params: { order: this.order.id, folder: this.order.folder.id } });
        }
    },

    setup() {

        if (!OrderStore.order) {
            throw "Order not found";
        }

        const order = ref<Order>(OrderStore.order);

        return {
            order,
        }
    },
});
