import { computed, defineComponent, ref } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router"
import { OrderStore } from "@/store/orderstate";
import { Order, Marking, MarkingImage } from "@/models/ordermodels";
import { RouteParams } from "@/router/RouteParams";
import Image from "@/components/Image.vue";
import EditImage from "./EditImage.vue";
import { MenuItemType } from "@/components/OrderSidebar";

export default defineComponent({

    components: {
        Image,
        EditImage
    },

    setup() {
        if (!OrderStore.order) {
            throw "Order not found";
        }
        const route = useRoute();
        const order = ref<Order>(OrderStore.order);
        const message = ref('');
        const images = computed<MarkingImage[]>(() => marking.value.images);
        const selectedImage = ref<MarkingImage>();
        const getMarking = (id: string): Marking => {
            const marking = order.value.markings.get(id);
            if (!marking) {
                throw "Marking not found";
            }
            return marking;
        }
        const marking = ref<Marking>(getMarking(new RouteParams(route.params).marking()));

        function selectImage (image?: MarkingImage) {
            selectedImage.value = image;
        }

        function updateImage (image: MarkingImage) {
            selectedImage.value = undefined;
            if (image != null) {
                const index: number = images.value?.findIndex(x => x.id === image.id) ?? -1;
                if (index !== -1) {
                    images.value?.splice(index, 1, {...image});
                }
                message.value = "MarkingImageUpdate:UpdateSuccessMessage";
            } else {
                message.value = "MarkingImageUpdate:UpdateErrorMessage";
            }
        };

        function deleteImage (imageId: string) {
            selectedImage.value = undefined;
            if (imageId != null) {
                const index: number = images.value?.findIndex(x => x.id === imageId) ?? -1;
                if (index !== -1) {
                    images.value?.splice(index, 1);
                }
                message.value = "MarkingImageUpdate:DeleteSuccessMessage";
            } else {
                message.value = "MarkingImageUpdate:DeleteErrorMessage";
            }
        }

        onBeforeRouteUpdate(async (to) => {
            marking.value = getMarking(new RouteParams(to.params).marking());
        });

        return {
            images,
            selectedImage,
            selectImage,
            updateImage,
            deleteImage,
            message,
            marking,
            order,
            MenuItemType
        }
    }
});
