<template>
  <div class="go-back__container">
    <button
      type="button"
      class="btn__round btn__with-icon--chevron-left btn__with-icon"
      @click="goBack"
    ></button>
    <span>{{ title }}</span>
    <div class="go-back__icon-container" v-if="showSettings">
      <router-link :to="{name: 'editOrder'}">
      <img src="../../assets/icons/settings.svg" alt="IconNotFound" />
      </router-link>
    </div>
  </div>
</template>

<script src="./GoBack.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./GoBack.scss";
</style>