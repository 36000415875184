<template>
  <div v-if="folder" class="context-menu__container">
    <ul v-if="active" class="context-menu__options">
      <li class="context-menu__option">
        <span @click="toggle(false)">
          <router-link :to="{ name: 'create-marking', params: { order: order.id, folder: folder.id } }" class="context-menu__option-link">
              <img
                src="../assets/icons/marking.svg"
                class="custom-svgs folder-tree__link-icon context-menu__icon"
                alt=""
              />
              {{$t("ContextMenu:Marking")}}
          </router-link>
        </span>
      </li>
      <li v-if="folderCreationEnabled" class="context-menu__option">
        <span @click="toggle(false)">
          <router-link :to="{ name: 'createfolder', params: { order: order.id, folder: folder.id }}" class="context-menu__option-link">
            <img
              src="../assets/icons/folder.svg"
              class="custom-svgs folder-tree__link-icon context-menu__icon"
              alt=""
            />
            {{$t("ContextMenu:Folder")}}
          </router-link>
        </span>
      </li>
    </ul>
    <button
      class="context-menu__button btn__round"
      @click="toggle"
      :class="{ 'context-menu__button--active': active }"
    >
      <svg class="custom-svgs">
          <use xlink:href="../assets/icons/sprite.svg#add"></use>
      </svg>
    </button>
  </div>
</template>

<script src="./FolderContextMenu.ts" lang="ts"></script>

<style lang="scss" scoped>
  @import "./ContextMenu.scss";
</style>
