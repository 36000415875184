import { computed, defineComponent, ref, watch } from "vue";
import { ContentService } from "@/services/ContentService";
import { CurtainVisualizerData } from "./CurtainVisualizerData";
import Curtain from "./Curtain.vue";

export default defineComponent({
    name: "CurtainVisualizer",
    components: {
        Curtain,
    },
    props: {
        data: {
            type: Object as () => CurtainVisualizerData,
            required: true,
        },
    },
    setup(props) {
        const textureUrl = ref<string | null>(null);
        const imageUrl = computed(() => props.data.imageUrl);
        const renderCurtain = computed<boolean>(() => props.data != null && textureUrl.value != null);
        watch(imageUrl, async (value) => {
            textureUrl.value = await ContentService.getBase64ImageUrl(value);
        }, {
            immediate: true
        });
        watch(() => props.data, data => {
            console.debug("Curtain Visualizer data", data);
        }, {
            immediate: true
        });
        return {
            textureUrl,
            renderCurtain,
        }
    }
});
