import { Options, Vue } from "vue-class-component";
import { OrderService } from "@/axios/orderservice";
import SideImage from "@/components/sideImage/SideImage.vue";
import GoBack from "@/components/goBack/GoBack.vue";
import { UserFriendlyError } from "@/models/errormodels";

@Options({
    name:"CreateOrder",
    components:{
        SideImage,
        GoBack,
    },
    computed: {
        isComplete() {
            return this.offerName !== '' && !this.offerSaving;
        }
    }
})
export default class CreateOrder extends Vue {
    success: boolean = false;
    error: boolean = false;
    offerName: string = "";
    note: string = "";
    offerSaving: boolean = false;

    async submit(): Promise<void> {
      this.offerSaving = true;
      this.success = false;
      this.error = false;
      const model = {
        offerName: this.offerName,
        note: this.note
      };
      await OrderService.createOrder(model).then((data) => {
        this.$router.push({ name: "order", params: { order: data.id } });
      }, (details: UserFriendlyError) => {
        this.error = true;
        this.offerSaving = false;
        console.error(details);
      });
    };
};
