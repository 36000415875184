<template>
  <div class="container-with-side-img">
    <side-image :hideOnMobileAndTablet="true" />
    <div class="manage-account">
      <go-back :title="$t('Manage:Account')" route-name="orders" />
      <div class="manage-account__inner-container content__container">
        <span class="manage-account__name">{{ currentUser.email }}</span>
        <ul class="list">
          <li class="list__item">
            <router-link :to="{ name: 'changepassword' }">
              <div class="list__content">
                <img
                  src="../../assets/icons/lock.svg"
                  class="custom-svgs"
                  alt=""
                />
                <span>{{ $t("Manage:ChangeAccountDetails") }} </span>
              </div>
            </router-link>
          </li>
          <li class="list__item" v-if="showUserOrderSharing">
            <router-link :to="{ name: 'userordersharing' }">
              <div class="list__content">
                <img
                  src="../../assets/icons/user.svg"
                  class="custom-svgs"
                  alt=""
                />
                <span>{{ $t("UserOrderSharing:MenuLabel") }} </span>
              </div>
            </router-link>
          </li>
          <li class="list__item">
            <router-link :to="{ name: 'help' }">
              <div class="list__content">
                <img
                  src="../../assets/icons/info.svg"
                  class="custom-svgs"
                  alt=""
                />
                <span> {{ $t("Manage:Help") }}</span>
              </div>
            </router-link>
          </li>
          <li class="list__item" v-on:click="onSignOut">
            <div class="list__content manage-account__logout-container">
              <img
                src="../../assets/icons/exit.svg"
                class="custom-svgs"
                alt=""
              />
              <span>{{ $t("Manage:SignOut") }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script src="./Manage.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./Manage.scss";
</style>
