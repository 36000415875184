import { defineComponent } from "vue";
import { Folder } from "@/models/ordermodels";
import FolderTreeView from "./FolderTreeView.vue";

const FolderList = defineComponent({

    components: {
        FolderTreeView,
    },

    props: {
        folder: {
            type: Object as () => Folder,
            required: true
        }
    }
});

export default FolderList;
