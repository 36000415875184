<template>
  <div class="folder-tree__item">
    <div
      class="folder-tree__item-container"
      :class="
        openPreview
          ? 'folder-tree__item-container--preview-open'
          : ''
      "
    >
      <div class="folder-tree__link-container">
        <div v-if="editMode"  class="folder-tree__link">
          <label class="checkbox__input-container checkbox-in-tree__container" >
            <input type="checkbox" @change="selectForEditing($event)" /> {{ marking.name }}
            <span class="checkbox__checkmark checkbox-in-tree__checkmark"></span>
          </label>
        </div>
        <router-link v-if="!editMode" class="folder-tree__link" :to="{ name: route, params: { order: order.id, marking: marking.id }}">
          <img src="../assets/icons/marking.svg" class="custom-svgs folder-tree__link-icon" alt="" />
          {{ marking.name }}
        </router-link>
        <div
          @click="handleCloseMarkingPreview"
          class="folder-tree__toggle-preview-container">
          <svg class="custom-svgs">
            <use xlink:href="../assets/icons/sprite.svg#chevron-left"></use>
          </svg>
        </div>
      </div>
      <div
        class="folder-tree__preview-container"
      >
        <marking-tile :marking="marking" />
      </div>
    </div>
  </div>
</template>

<script src="./MarkingTreeView.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./MarkingTreeView.scss";
</style>
