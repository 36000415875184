<template>
  <div class="container-with-tree">
    <div v-show="!selectedImage" class="content__container marking-image-list">
      <button class="btn__round marking__preview-btn marking-image-list__back-button" type="button">
        <router-link
          :to="{
            name: 'folderview',
            params: { order: order.id, folder: order.folder.id  },
            meta: {orderSideBar: MenuItemType.Images}
          }"
        >
          <img src="~@/assets/icons/close.svg" class="custom-svgs" alt="x" />
        </router-link>
      </button>
      <h1 class="marking-image-list__header">{{ marking.name }}</h1>
      <p class="marking-image-list__nr-of-objects">
        {{
          images.length > 0
            ? images.length + ` ${$t("FolderTile:FolderObjects")}`
            : $t("FolderTile:EmptyFolder")
        }}
      </p>

      <div class="tile__grid-content marking-image-list__grid-content">
        <router-link :to="{ name: 'create-marking-image', params: { order: order.id, marking: marking.id }}" class="tile tile__add marking-image-list__tile">

            <div>
              <svg class="tile__add-svg custom-svgs ">
                <use xlink:href="../../assets/icons/sprite.svg#add "></use>
              </svg>
            </div>

        </router-link>
        <div v-for="image in images" :key="image.id" class="tile marking-image-list__tile">
          <div
            class="tile__preview-container marking-image-list__preview-container"
          >
            <Image
              :file-id="image.thumbnailFileId"
              height="200"
              width="200"
              @click="selectImage(image)"
              class="marking-image-list__image"
            />
          </div>

          <div
            class="marking-image-list__text-container tile__content-container"
          >
            <h3 class="marking-image-list__name">{{ image.name }}</h3>
            <div class="marking-image-list__text">{{ image.note }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="selectedImage"
      :class="{ 'marking-image-list__overlay': selectedImage }"
    >
      <div class="marking-image-list__overlay-scroll">
        <EditImage
          v-if="selectedImage"
          :image="selectedImage"
          @update="updateImage"
          @delete="deleteImage"
          @close="selectImage()"
        />
      </div>
    </div>
  </div>
</template>

<script src="./MarkingImageList.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "MarkingImageList.scss";
</style>
