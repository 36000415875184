import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { AccountStore } from "@/store/accountstate";
import { OrderStore } from "@/store/orderstate";
import { Order, OrderStatus } from "@/models/ordermodels";

interface State {
    heading: string;
    routerLinkRouteName: string;
    routerLinkText: string;
}

export default defineComponent({
    name: "OrderCompleted",
    setup() {
        const translate = useI18n().t;
        const order = computed<Order>(() => OrderStore.order as Order);
        const internalSalesSven = computed<boolean>(() => !!AccountStore.currentUser?.internalSalesSven);
        const state = computed<State>(() => ({
            heading: translate(order.value.status === OrderStatus.Initiated ? "OrderPdf:SendEmailSuccessMessage" : "PreviewOffer:OfferPlaced"),
            routerLinkRouteName: internalSalesSven.value ? "searchorders" : "orders",
            routerLinkText: translate(internalSalesSven.value ? "PreviewOffer:BackToSearchOrders" : "PreviewOffer:BackToOffers"),
        }));

        return {
            order,
            state,
        }
    }
});
