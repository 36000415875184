import { BareActionContext, getStoreBuilder } from "vuex-typex";
import { RootState } from "./index";

export interface ChangeState {
  editMode: boolean;
  markings: SelectedItem[];
  folders: SelectedItem[];
}

export interface SelectedItem {
  id: string;
  name: string;
}

export interface CheckboxState extends SelectedItem {
  checked: boolean
}

const initialState: ChangeState = {
  editMode: false,
  markings: [],
  folders: []
}

const store = getStoreBuilder<RootState>().module("change", initialState);

// private getters
function getEdit(state: ChangeState): boolean {
  return state.editMode;
}
function getMarkings(state: ChangeState): SelectedItem[] {
  return state.markings;
}
function getFolders(state: ChangeState): SelectedItem[] {
  return state.folders;
}

// getters
const getStateEdit = store.read(getEdit, "getEdit");
const getStateMarkings = store.read(getMarkings, "getMarkings");
const getStateFolders = store.read(getFolders, "getFolders");

// mutations
function mutateEdit(state: ChangeState, editMode: boolean): Promise<void> {
  return new Promise((resolve) => {
    state.editMode = editMode;
    resolve();
  });
}
function mutateMarkingState(state: ChangeState, checkbox: CheckboxState): Promise<void> {
  return new Promise((resolve) => {
    const index = state.markings.findIndex((item) => item.id === checkbox.id);
    if (index !== -1) {
      state.markings.splice(index, 1);
    } else {
      state.markings.push({ id: checkbox.id, name: checkbox.name });
    }
    resolve();
  });
}
function mutateFolderState(state: ChangeState, checkbox: CheckboxState): Promise<void> {
  return new Promise((resolve) => {
    const index = state.folders.findIndex((item) => item.id === checkbox.id);
    if (index !== -1) {
      state.folders.splice(index, 1);
    } else {
      state.folders.push({ id: checkbox.id, name: checkbox.name });
    }
    resolve();
  });
}
function mutateClearState(state: ChangeState): Promise<void> {
  return new Promise((resolve) => {
    state.folders = [];
    state.markings = [];
    state.editMode = false;
    resolve();
  });
}

// actions
async function saveEdit(context: BareActionContext<ChangeState, RootState>, editMode: boolean): Promise<void> {
  return await mutateEdit(context.state, editMode);
}
async function saveMarkingState(context: BareActionContext<ChangeState, RootState>, state: CheckboxState): Promise<void> {
  return await mutateMarkingState(context.state, state);
}
async function saveFolderState(context: BareActionContext<ChangeState, RootState>, state: CheckboxState): Promise<void> {
  return await mutateFolderState(context.state, state);
}
async function clearState(context: BareActionContext<ChangeState, RootState>): Promise<void> {
  return await mutateClearState(context.state);
}

// export store
export const ChangeStore = {
  get editMode(): boolean { return getStateEdit(); },
  get markings(): SelectedItem[] { return getStateMarkings(); },
  get folders(): SelectedItem[] { return getStateFolders(); },
  clearState: store.dispatch(clearState, "clearState"),
  saveEditMode: store.dispatch(saveEdit, "saveEdit"),
  saveMarkingState: store.dispatch(saveMarkingState, "saveMarkingState"),
  saveFolderState: store.dispatch(saveFolderState, "saveFolderState")
};
