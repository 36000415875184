<template>
  <div v-if="folder" class="create-marking__container container-with-tree">
    <div class="create-marking__preview-container">
      <button
        class="btn__round create-marking__preview-btn"
        type="button"
        @click="handleOnCloseClick()"
      >
        <img src="../../assets/icons/close.svg" class="custom-svgs" alt="x" />
      </button>
      <div class="create-marking__preview-image step-1-default-img" />
    </div>

    <div class="create-marking__content-container">
      <ul class="breadcrumb">
        <li>{{ order.offerName }}</li>
        <li v-if="folder.parentId">{{ folder.name }}</li>
        <li>{{ $t("Marking:CreateMarkingHeading") }}</li>
      </ul>

      <h1>{{ $t("Marking:CreateMarkingHeading") }}</h1>

      <form class="create-marking__form" id="markingform" @submit.prevent="submit">
        <div class="input__container create-marking__content-input">
          <label class="input__label--thin">{{
            $t("Marking:CreateMarkingLabel")
          }}</label>
          <input
            class="input__icon input__icon--marking input__size--large"
            type="text"
            maxlength="20"
            v-model="markingName"
            required
          />
        </div>
        
      </form>
    </div>
    <div class="wizard-bottom-bar__container create-marking__bottom-bar">
          <button
            class="btn__default--primary"
            :disabled="!isComplete"
            :class="{ btn__disabled: !isComplete }"
            form="markingform"
          >
            {{ $t("Marking:CreateMarkingButton") }}
          </button>
        </div>
    <!-- <button>{{ $t("Marking:SaveMarkingButton") }}</button> -->
  </div>
</template>

<script src="./CreateMarking.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./CreateMarking.scss";
</style>