<template>
  <div
    v-if="order.id"
    class="preview-order container-with-tree "
    :class="{ 'preview-order-modifications': orderStatusSentOrPlaced }"
  >
    <div class="preview-order__outer-container">
      <div v-if="orderStatusInitiated" class="preview-order__close">
        <go-back
          title="Beställ"
          routeName="order"
          :routeParams="{ order: order.id }"
        />
        <router-link :to="{ name: 'order' }" class="preview-order__close-btn">
          <button
            class="btn__round btn__with-icon btn__with-icon--close"
          ></button>
        </router-link>
      </div>
      <div v-else>
        <div v-if="currentUser.internalSalesSven" class="preview-order__close-btn">
          <go-back :title="order.offerName" routeName="searchorders" />
        </div>
        <div v-else>
          <go-back :title="order.offerName" routeName="orders" />
        </div>
      </div>

      <div class="preview-order__inner-container">
        <div class="container-limited--side-img">
          <h1 v-if="orderStatusSentOrPlaced">
            {{ translate("PreviewOrder:HeaderWhenOrderPlaced") }}
          </h1>
          <div class="preview-order__about-order">
            <h1 v-if="orderStatusInitiated" class="preview-order__header">
              {{ translate("PreviewOrder:HeaderWhenInitiated") }}
            </h1>
            <div>
              {{
                `${translate("PreviewOrder:OrderNumber")}: ${order.offerNumber}`
              }}
            </div>
            <div v-show="order.customerOrderNumber">
              {{
                `${translate("PreviewOrder:CustomerOrderNumber")}: ${order.customerOrderNumber}`
              }}
            </div>
            <div v-if="order.offerSentToCustomerAtFormatted">
              {{
                `${translate("PreviewOrder:OrderDate")}:  ${
                  order.offerSentToCustomerAtFormatted
                }`
              }}
            </div>
          </div>

          <ul class="folder-tree preview-order__folder-tree">
            <preview-order-folder-view :folder="order.folder" />
          </ul>

          <div class="preview-order__details-container">
            <ul class="preview-order__details-list">
              <li>
                <span> {{ translate("PreviewOrder:OrderTotalNetAmountLabel") }}: </span>
                <span> {{ order.orderTotalNetAmount.formatted }} </span>
              </li>
              <li v-show="showOrderTotalDiscountAmount">
                <span>
                  {{ translate("PreviewOrder:OrderTotalDiscountAmountLabel") }}:
                </span>
                <span> {{ order.orderTotalDiscountAmount.formatted }} </span>
              </li>
              <li>
                <span> {{ translate("PreviewOrder:FreightCost") }}: </span>
                <span> {{ order.freightCost.formatted }} </span>
              </li>
              <li>
                <span>
                  {{ translate("PreviewOrder:OrderTotalExclVat") }}:
                </span>
                <span>{{ order.orderTotalExclVat.formatted }}</span>
              </li>
              <li>
                <span> {{ translate("PreviewOrder:OrderVat") }}: </span>
                <span> {{ order.orderVat.formatted }} </span>
              </li>
              <li class="preview-order__price">
                <span>
                  {{
                    translate("PreviewOrder:OrderTotalInclVatAndFreightCost")
                  }}:
                </span>
                <span>
                  {{ order.orderTotalInclVatAndFreightCost.formatted }}
                </span>
              </li>
            </ul>
          </div>

          <div v-if="errorMessage">
            {{ errorMessage }}
          </div>

          <div
            class="preview-order__action-btns"
            v-if="orderStatusSentOrPlaced"
          >
            <button  v-show="showDownloadOrderImages" @click="downloadOrderImages" class="btn__default--primary download-img">
            
              <span class="download-img__container">
                {{ $t("PreviewOrder:DownloadOrderImages") }}
                <img
                      src="../../assets/icons/download.svg"
                      class="custom-svgs"
                      alt=""
                />
              </span>
            </button>
            
            <!-- Button - Change offer -->
            <button
              v-if="showChangeOrder"
              @click="changeOrder"
              class="btn__default--primary"
            >
              {{ $t("PreviewOrder:EditOrder") }}
            </button>

            <!-- Button - Place order - step 1 -->
            <button
              v-if="showPlaceOrderStep1ShowDeliveryAddress"
              @click="showHideCustomerInformation"
              class="btn__default--primary"
            >
              {{ $t("PreviewOrder:AddDeliveryAddress") }}
            </button>

            <!-- Button - Copy to new offer -->
            <button
              v-if="showCopyOrder"
              @click="createOrderCopy"
              class="btn__default--primary"
            >
              {{ $t("PreviewOrder:CopyToNewOrder") }}
            </button>
          </div>
          <div
            v-if="showCustomerInformation"
            class="preview-order__delivery-container"
          >
            <div class="preview-order__delivery-content">
              <button
                class="btn__round btn__with-icon btn__with-icon--close preview-order__delivery-close"
                @click="showHideCustomerInformation"
              ></button>
              <Form class="preview-order__form" @submit="placeOrder">
                <h2>{{ $t("PreviewOrder:AddDeliveryAddress") }}</h2>
                <div class="input__container">
                  <label
                    class="input__label--small input__label"
                    for="customerOrderNumber"
                    >{{ $t("PreviewOrder:CustomerOrderNumber") }}</label
                  >
                  <Field
                    id="customerOrderNumber"
                    name="customerOrderNumber"
                    as="input"
                    type="text"
                    :label="$t('PreviewOrder:CustomerOrderNumber')"
                    rules="required"
                    autocomplete="off"
                    v-model="customerInformation.customersOrderNumber"
                  />
                  <ErrorMessage
                    name="customerOrderNumber"
                    class="input__error-msg"
                  />
                </div>
                <div class="input__container">
                  <label
                    class="input__label--small input__label"
                    for="requestedDeliveryDate"
                    >{{ $t("PreviewOrder:RequestedDeliveryDate") }}</label
                  >
                  <Field
                    id="requestedDeliveryDate"
                    name="requestedDeliveryDate"
                    as="input"
                    type="date"
                    :label="$t('PreviewOrder:RequestedDeliveryDate')"
                    rules="required"
                    autocomplete="off"
                    v-model="customerInformation.requestedDeliveryDate" @change="updateWeekNumber($event)"
                  />
                  {{$t("PreviewOrder:RequestedDeliveryWeek")}}: {{ weekNumber }}
                  <ErrorMessage
                    name="requestedDeliveryDate"
                    class="input__error-msg"
                  />
                </div>
                <div class="input__container">
                  <label
                    class="input__label--small input__label"
                    for="cellPhone"
                    >{{ $t("PreviewOrder:CellPhone") }}</label
                  >
                  <Field
                    id="cellPhone"
                    name="cellPhone"
                    as="input"
                    type="number"
                    :label="$t('PreviewOrder:CellPhone')"
                    rules="required"
                    autocomplete="off"
                    v-model="customerInformation.cellPhone"
                  />
                  <ErrorMessage name="cellPhone" class="input__error-msg" />
                </div>
                <div class="input__container">
                  <label
                    class="input__label--small input__label"
                    for="deliveryAddressCompanyName"
                    >{{ $t("PreviewOrder:DeliveryAddressCompanyName") }}</label
                  >
                  <Field
                    id="deliveryAddressCompanyName"
                    name="deliveryAddressCompanyName"
                    as="input"
                    type="text"
                    :label="$t('PreviewOrder:DeliveryAddressCompanyName')"
                    autocomplete="off"
                    v-model="customerInformation.deliveryAddressCompanyName"
                  />
                  <ErrorMessage
                    name="deliveryAddressCompanyName"
                    class="input__error-msg"
                  />
                </div>
                <div class="input__container">
                  <label
                    class="input__label--small input__label"
                    for="address1"
                    >{{ $t("PreviewOrder:AddressLine1") }}</label
                  >
                  <Field
                    id="address1"
                    name="address1"
                    as="input"
                    type="text"
                    :label="$t('PreviewOrder:AddressLine1')"
                    rules="required"
                    autocomplete="off"
                    v-model="customerInformation.address1"
                  />
                  <ErrorMessage name="address1" class="input__error-msg" />
                </div>
                <div class="input__container">
                  <label
                    class="input__label--small input__label"
                    for="address1"
                    >{{ $t("PreviewOrder:AddressLine2") }}</label
                  >
                  <Field
                    id="address1"
                    name="address2"
                    as="input"
                    type="text"
                    :label="$t('PreviewOrder:AddressLine2')"
                    autocomplete="off"
                    v-model="customerInformation.address2"
                  />
                  <ErrorMessage name="address2" class="input__error-msg" />
                </div>
                <div class="input__container">
                  <label
                    class="input__label--small input__label"
                    for="zipcode"
                    >{{ $t("PreviewOrder:ZipCode") }}</label
                  >
                  <Field
                    id="zipcode"
                    name="zipcode"
                    as="input"
                    type="number"
                    :label="$t('PreviewOrder:ZipCode')"
                    rules="required"
                    autocomplete="off"
                    v-model="customerInformation.zipcode"
                  />
                  <ErrorMessage name="zipcode" class="input__error-msg" />
                </div>
                <div class="input__container">
                  <label class="input__label--small input__label" for="city">{{
                    $t("PreviewOrder:City")
                  }}</label>
                  <Field
                    id="city"
                    name="city"
                    as="input"
                    type="text"
                    :label="$t('PreviewOrder:City')"
                    rules="required"
                    autocomplete="off"
                    v-model="customerInformation.city"
                  />
                  <ErrorMessage name="city" class="input__error-msg" />
                </div>
                <div class="input__container">
                  <label
                    class="input__label--small input__label"
                    for="country"
                    >{{ $t("PreviewOrder:Country") }}</label
                  >
                  <div class="dropdown__container">
                    <select
                      class="dropdown"
                      id="country"
                      v-model="customerInformation.country"
                    >
                      <option
                        v-for="country in countryList"
                        :key="country.code"
                        :value="country.code"
                        :selected="country.code === customerInformation.country"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Button - Place order - step 2 -->
                <button
                  v-if="showPlaceOrderStep2"
                  class="btn__default--primary"
                  :disabled="!isComplete"
                  :class="{ btn__disabled: !isComplete }"
                  type="submit"
                >
                  {{ $t("PreviewOrder:SendToERP") }}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="wizard-bottom-bar__container preview-order__bottom-container"
      v-show="orderStatusInitiated"
    >
     <button v-show="showDownloadOrderImages" @click="downloadOrderImages" class="btn__default--primary download-img">           
              <span class="download-img__container">
                 {{ $t("PreviewOrder:DownloadOrderImages") }}
                <img
                      src="~@/assets/icons/download.svg"
                      class="custom-svgs"
                      alt=""
                />
              </span>
      </button>     
     <!-- Button - Send offer PDF -->
      <button
        class="btn__default--primary"
        @click="sendOrderPdf()"
        v-if="showSendOrderPdf"
      >
        {{ translate("PreviewOrder:SendOrderPdf") }}
      </button>
      
     
      <div v-if="showEmailSendResponse">
        <div>{{ emailSendResponseText }}</div>
        <div>
          {{ translate("OrderPdf:SendEmailSuccessOfferNumberText")
          }}{{ order.offerNumber }}
        </div>
        <button @click="closeEmailSendResponseBox()">
          {{ translate("OrderPdf:SendEmailSuccessBackButtonText") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./PreviewOrder.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./PreviewOrder.scss";
</style>
