import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { FabricMarkingGroup, Order } from "@/models/ordermodels";
import { ConfigurationType, CurtainOrientationType, SewingType } from "@/models/configuratormodels";
import { OrderStore } from "@/store/orderstate";

const FabricViewer = defineComponent({
    setup() {
        const translate = useI18n().t;
        const order = computed<Order>(() => (OrderStore.order as Order));
        const fabricModels = computed<FabricMarkingGroup[]>(() => order.value.fabricMarkingList);

        function showSimpleConfiguration(markingId: string): boolean {
            const orderLine = order.value.orderLines.find(x => x.markingId === markingId);
            if (orderLine) {
                return orderLine.curtainConfiguration === ConfigurationType.Simple;
            }
            return false;
        }

        function showCoverwallConfiguration(markingId: string): boolean {
            const orderLine = order.value.orderLines.find(x => x.markingId === markingId);
            if (orderLine) {
                return orderLine.curtainConfiguration === ConfigurationType.CoverWall;
            }
            return false;
        }

        function getCurtainOrientationName(type: CurtainOrientationType): string {
            return translate("ConfiguratorEditor:CurtainOrientationType" + CurtainOrientationType[type] + "Label");
        }

        function getSewingTypeName(type: SewingType): string {
            return translate("ConfiguratorEditor:SewingType" + SewingType[type] + "Label");
        }

        return {
            order,
            fabricModels,
            getSewingTypeName,
            getCurtainOrientationName,
            showSimpleConfiguration,
            showCoverwallConfiguration
        };
    },
});

export default FabricViewer;
