import { Store } from "vuex";
import { getStoreBuilder } from "vuex-typex";

// Each module must be explicitly imported here
// so that they are built before the store is built.
import "./accountstate";
import "./orderstate";
import "./changestate";
import { AccountState } from "./accountstate";
import { OrderState } from "./orderstate";
import { ChangeState } from "./changestate";

export interface RootState {
  orderState: OrderState;
  accountState: AccountState;
  changestate: ChangeState;
}

export const store: Store<RootState> = getStoreBuilder<RootState>().vuexStore();
