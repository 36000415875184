import { computed, defineComponent, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { OrderStore } from "@/store/orderstate";
import { Folder, Order } from "@/models/ordermodels";

const ContextMenu = defineComponent({

    emits: [
        "context-click"
    ],

    methods: {
        toggle (_: MouseEvent, toggle?: boolean) {
            if (toggle === undefined) {
                this.active = !this.active
            } else {
                this.active = toggle;
            }
            this.$emit("context-click", this.active);
        },
    },

    setup () {
        const order = computed(() => OrderStore.order as Order);
        const folder = computed(() => OrderStore.folder as Folder);
        const active = ref<boolean>(false);
        const folderCreationEnabled = computed<boolean>(() => folder.value.id === order.value.folder.id);

        onBeforeRouteUpdate(() => {
            active.value = false;
        });

        return {
            folder,
            order,
            folderCreationEnabled,
            active,
        };
    },
});

export default ContextMenu;
