<template>
  <div v-if="folder" class="create-marking__container container-with-tree">
    <div class="create-marking__preview-container">
      <router-link
        :to="{
          name: 'order',
          params: { order: order.id, folder: folder.id },
        }"
        class="context-menu__option-link"
      >
        <button class="btn__round create-marking__preview-btn" type="button">
          <img src="~@/assets/icons/close.svg" class="custom-svgs" alt="x" />
        </button>
      </router-link>
      <div class="create-marking__preview-image icon--folder" />
    </div>
    <div class="create-marking__content-container">
      <ul class="breadcrumb">
        <li>{{ order.offerName }}</li>
        <li v-if="folder.parentId">{{ folder.name }}</li>
        <li>{{ $t("Folder:CreateFolderHeading") }}</li>
      </ul>
      <h1>{{ $t("Folder:CreateFolderHeading") }}</h1>
      <div class="create-marking__form">
        <label class="input__label--thin">{{
          $t("Folder:CreateFolderLabel")
        }}</label>
        <input
          type="text"
          class="input__icon input__icon--folder input input__size--large"
          maxlength="20"
          v-model="folderName"
        />
      </div>
    </div>
      <div class="wizard-bottom-bar__container create-marking__bottom-bar">
        <form @submit.prevent="submit">
          <button class="btn__default--primary" :class="{ btn__disabled: submitButtonIsDisabled }" :disabled="submitButtonIsDisabled">
            {{ $t("Folder:CreateFolderButton") }}
          </button>
        </form>
    </div>
  </div>
</template>

<script src="./CreateFolder.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./CreateMarking.scss";
</style>
