import { defineComponent, ref } from "vue";
import { ContentService } from "@/services/ContentService";
import SideImage from "../../components/sideImage/SideImage.vue"
import GoBack from "../../components/goBack/GoBack.vue"

export default defineComponent({
    name: "Help",

    components: {
        SideImage,
        GoBack
    },

    async created() {
        this.content = await ContentService.getHelp();
    },

    setup() {
        const content = ref<string>();

        return {
            content
        }
    }
})
