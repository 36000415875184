<template>
  <div class="configurator__sub-container-header">
    <h1>{{ $t("ConfiguratorEditor:Heading") }}</h1>

    <span>{{ selectedVariant?.name }} {{ selectedVariant?.width }} cm</span>
  </div>

  <div class="configurator__content-container">
    <div
      class="configurator__sub-container-1 configurator__sub-container-content"
    >
      <div class="configurator__switch-container">
        <label class="switch-with-label" for="configuratorType">
          <input
            type="checkbox"
            v-model="configurationTypeSelectedBool"
            id="configuratorType"
          />
          <div class="slider"></div>
          <div
            class="switch-with-label__label"
            v-for="configurationType in configurationTypeList"
            :key="configurationType"
          >
            {{
              $t(
                "ConfiguratorEditor:ConfigurationType" +
                  getConfigurationTypeName(configurationType) +
                  "Label"
              )
            }}
          </div>
        </label>
      </div>

      <div class="configurator__slider-container">
        <div v-if="showWallWidth()" class="configurator__slider-item">
          <label
            for="rangeWallWidth"
            class="range-label configurator__heading"
            >{{ $t("ConfiguratorEditor:WallWidthLabel") }}</label
          >
          <div class="range-slider">
            <vue-slider
              v-model="wallWidthSelected"
              :drag-on-click="true"
              :min="wallWidthMinAllowed"
              :max="wallWidthMaxAllowed"
              :tooltip="'focus'"
              :height="12"
              :dotSize="24"
              :contained="true"
              :lazy="true"
              :silent="true"
              @drag-end="wallWidthSliderOnDragEnd()"
              ref="wallWidthSlider"
              name="rangeWallWidth"
            />
            <div class="range-value">
              <input
                type="number"
                :min="wallWidthMinAllowed"
                :max="wallWidthMaxAllowed"
                name="sliderWallWidth"
                v-model="wallWidthSelected"
                @blur="validateWallWidthSelected"
                class="configurator__slider-input"
              />
            </div>
          </div>
        </div>

        <div class="configurator__slider-item">
          <label
            for="rangeWallHeight"
            class="range-label configurator__heading"
            >{{ $t("ConfiguratorEditor:WallHeightLabel") }}</label
          >
          <div class="range-slider">
            <vue-slider
              v-model="wallHeightSelected"
              :drag-on-click="true"
              :min="wallHeightMinAllowed"
              :max="wallHeightMaxAllowed"
              :tooltip="'focus'"
              :height="12"
              :dotSize="24"
              :contained="true"
              :lazy="true"
              :silent="true"
              @drag-end="wallHeightSliderOnDragEnd()"
              ref="wallHeightSlider"
              name="rangeWallHeight"
            />
            <div class="range-value">
              <input
                type="number"
                :min="wallHeightMinAllowed"
                :max="wallHeightMaxAllowed"
                name="sliderWallHeight"
                v-model="wallHeightSelected"
                @blur="validateWallHeightSelected"
                class="configurator__slider-input"
              />
            </div>
          </div>
        </div>

        <div class="configurator__height-deduction">
          <span>
            {{
              `${heightDeduction} ${$t(
                "ConfiguratorEditor:HeightDeductionLabel"
              )}`
            }}
          </span>
          <span @click="showHeightDeduction = !showHeightDeduction" style="cursor: pointer">{{ $t("ConfiguratorEditor:ChangeHeightDeduction") }}</span>
          <input
            v-show="showHeightDeduction"
            type="number"
            name="txtHeightDeduction"
            v-model.lazy="heightDeduction"
            :max="heightDeductionMaxAllowed"
            :min="heightDeductionMinAllowed"
            @keyup.enter.prevent="$event.target.blur()"
          />
        </div>
      </div>

      <div
        class="configurator__checkbox-group-container configurator__simpleWall-checkbox-container"
        v-show="showSimpleWallCheckboxContainer()"
      >
        <div v-show="showSplitCurtain()">
          <label class="radio__input-container">
            <input
              type="checkbox"
              name="chkSplitCurtain"
              v-model="splitCurtain"
            />
            <span class="radio__checkmark radio__checkmark--use-boxshadow" />
            {{ $t("ConfiguratorEditor:SplitCurtainLabel") }}
          </label>
        </div>

        <div>
          <label
            class="radio__input-container"
          >
            <input
              type="checkbox"
              name="chkUseWeights"
              v-model="useWeights"
            />
            <span class="radio__checkmark radio__checkmark--use-boxshadow" />
            {{ $t("ConfiguratorEditor:UseWeightsLabel") }}
          </label>
        </div>
      </div>
    </div>

    <div
      class="configurator__sub-container-2 configurator__sub-container-content"
    >
      <div
        class="configurator__checkbox-group-container configurator__coverWall-checkbox-container"
        v-show="showCoverWallCheckBoxContainer()"
      >
        <div>
          <label class="radio__input-container radio__input--grey-on-unchecked " :class="showSewTogether() ? '' : 'radio__input--disabled'">
            <input type="checkbox" name="test" v-model="sewTogether" class="" :disabled="!showSewTogether()"/>
            <span class="radio__checkmark radio__checkmark--use-boxshadow" />
            <span>{{ $t("ConfiguratorEditor:SewTogetherLabel") }}</span>
          </label>
        </div>

        <div v-show="orientationLayingCondition()">
          <label class="radio__input-container radio__input--grey-on-unchecked" :class="showOrientationLaying() ? '' : 'radio__input--disabled'">
            <input type="checkbox" name="test" v-model="orientationLaying" :disabled="!showOrientationLaying()"/>

            <span class="radio__checkmark radio__checkmark--use-boxshadow" />
            <span>{{ $t("ConfiguratorEditor:OrientationLayingLabel") }}</span>
          </label>
        </div>

        <div>
          <label class="radio__input-container">
            <input type="checkbox" name="chkUseWeights" v-model="useWeights" />
            <span class="radio__checkmark radio__checkmark--use-boxshadow" />
            {{ $t("ConfiguratorEditor:UseWeightsLabel") }}
          </label>
        </div>
      </div>

      <span
        for="radSewingType"
        class="radio__group-header configurator__heading"
      >
        {{ $t("ConfiguratorEditor:SewingTypeLabel") }}
      </span>
      <div class="configurator__radio-btns-container">
        <div
          v-for="sewingType in sewingTypeList"
          :key="sewingType"
          class="configurator__radio-container"
        >
          <label class="radio__input-container">
            <input
              type="radio"
              :value="sewingType"
              v-model="sewingTypeSelected"
            />
            <span class="radio__checkmark"></span>
            {{
              $t(
                "ConfiguratorEditor:SewingType" +
                  getSewingTypeName(sewingType) +
                  "Label"
              )
            }}</label
          >
        </div>
      </div>
    </div>

    <div
      class="configurator__sub-container-3 configurator__sub-container-content"
    >
      <div>
        <div>
          <h2 class="configurator__heading">
            {{ $t("ConfiguratorEditor:OutputHeading") }}
          </h2>
        </div>
        <template
          v-for="curtainDrop in calculatedCurtainDrops.curtainDrops"
          :key="curtainDrop.quantity"
        >
          <div class="configurator__summary">
            <span>
              {{ $t("ConfiguratorEditor:OutputWidthLabel") }}
              {{ $filters.roundDecimals(curtainDrop.finishedWidthPerCurtainDrop) }}
            </span>

            <span>
              {{ $t("ConfiguratorEditor:OutputHeightLabel") }}
              {{  $filters.roundDecimals(curtainDrop.finishedHeightPerCurtainDrop) }}
            </span>

            <span class="configurator__summary-quantity" v-if="selectedConfigurationType == 1">
              {{ curtainDrop.quantity }} {{ $t("Marking:PiecesShort") }}
            </span>

            <span>{{ getCurtainPriceText(curtainDrop) }}</span>
          </div>
        </template>
      </div>

      <div class="configurator__summary-total" v-show="!showErrorMessages()">
        <div v-if="showWidthCovered()">
          {{ $t("ConfiguratorEditor:totalWidthCoveredCMLabel") }}
          {{ $filters.roundDecimals(calculatedCurtainDrops.totalWidthCovered) + " cm" }}
        </div>
        <div>
          {{ $t("ConfiguratorEditor:totalFabricLengthNeededCMLabel") }}
          {{ $filters.roundDecimals(calculatedCurtainDrops.totalFabricLengthNeeded) + " cm" }}
        </div>
        <div v-show="calculatedCurtainDrops.underHem > 0">
          {{ $t("ConfiguratorEditor:underHemCMLabel") }}
          {{ $filters.roundDecimals(calculatedCurtainDrops.underHem - 2) + " cm" }}
        </div>
      </div>

      <div>
        <div>
          <h2 class="configurator__heading">
            {{ $t("ConfiguratorEditor:Quantity") }}
          </h2>
        </div>
      </div>

      <div
        class="dropdown__container configurator__dropdown-container"
      >
        <label>
          <select class="dropdown" name="txtQuantity" v-model="quantity">
            <option v-for="i in quantityMaxAllowed" :key="i" :value="i">
              {{ i }} {{ $t("Marking:PiecesShort") }}
            </option>
          </select>
        </label>
      </div>
      <div v-show="showTipMessages()" class="marking__preview-tip-messages">
        <p v-for="(tipMessage) in tipMessages" :key="tipMessage">            
          {{ tipMessage }}
        </p>
      </div>
      <div v-show="showErrorMessages()" class="configurator__error">
        {{ errorMessages }}
      </div>
    </div>
  </div>
</template>

<script src="./FabricEditor.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./FabricEditor.scss";
</style>

