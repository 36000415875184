import { Options, Vue } from "vue-class-component";
import { AccountService } from "@/axios/accountservice";
import { PunchoutLogin } from "@/models/accountmodels";
import { AccountStore } from "@/store/accountstate";
import { Guid } from "guid-typescript";
import { UserFriendlyError } from "@/models/errormodels";

@Options({})
export default class Punchout extends Vue {

    protected errorMessage = "";
    protected sessionID = "";

    created(): void {
        this.sessionID = (this.$route.query.sessionID || "").toString();
        if (!Guid.isGuid(this.sessionID)) {
            this.errorMessage = this.$t("InvalidPunchoutSession");;
            return;
        }
        this.tryPunchout(this.sessionID);
    }

    protected isShowLoading(): boolean {
        return !this.errorMessage;
    }

    protected tryPunchout(sessionId: string): void {
        const model: PunchoutLogin = {
            sessionID: sessionId
        };
        this.errorMessage = "";
        AccountService.punchoutLogin(model).then((data) => {
            AccountStore.saveUser(data.currentUser);
            AccountStore.saveToken(data.token);
            const query = this.$route.query;
            if (query && query.redirect) {
                this.$router.push({ path: query.redirect as string });
            } else {
                this.$router.push({ name: "orders" });
            }
        }, (details: UserFriendlyError) => {
            this.errorMessage = details.message;
        });
    }
}
