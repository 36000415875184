import { defineComponent } from "vue";

const SideImage = defineComponent({

    name: "SideImage",

    components: {

    },

    props: {
        hideOnMobileAndTablet: {
            type: Boolean,
            default: false,
        }
    },


});

export default SideImage;
