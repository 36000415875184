<template>
  <router-link
    :to="{
      name: 'marking',
      params: { order: order.id, marking: marking.id },
    }"
  >
    <div class="tile marking-tile">
      <div class="tile__content-container">
        <div class="marking-tile__name">
          {{ markingSummary.markingName }}
        </div>
        <template v-if="showSimpleConfiguration">
          <div
            v-for="(c, index) in markingSummary.curtainDrops"
            :key="`summary-${index}`"
            class="marking-tile__content"
          >
            <span class="marking-tile__content-fabric">{{
              markingSummary.productName
            }}</span>
            <span class="marking-tile__content-measures"
              >{{ $t("ConfiguratorEditor:OutputWidthLabel") }}
              {{ $filters.roundDecimals(c.finishedWidthPerCurtainDrop) }} x {{ $t("ConfiguratorEditor:OutputHeightLabel") }}
              {{
                $filters.roundDecimals(c.finishedHeightPerCurtainDrop)
              }} {{ c.quantity }} {{ $t("Marking:PiecesShort") }}</span
            >
            <span class="marking-tile__content-sewing-type"
              >{{ markingSummary.sewingType }}</span
            >
            <span class="marking-tile__content-price">{{
              markingSummary.price.formatted
            }}</span>
          </div>
        </template>
        <template v-if="showCoverwallConfiguration">
          <div class="marking-tile__content">
            <span class="marking-tile__content-fabric">
              <span>{{ markingSummary.productName }}</span>
            </span>
            <span class="marking-tile__content-measures">
              <span>
                {{ $t("ConfiguratorEditor:OutputWidthLabel") }}
                {{
                  $filters.roundDecimals(markingSummary.totalFinishedWidth)
                }}
                x {{ $t("ConfiguratorEditor:OutputHeightLabel") }}
                {{ $filters.roundDecimals(markingSummary.finishedHeight) }} 
                {{ markingSummary.quantity }} {{ $t("Marking:PiecesShort") }}
              </span>
            </span>
            <span class="marking-tile__content-sewing-type">{{
              markingSummary.sewingType
            }}</span>
            <span class="marking-tile__content-price">{{
              markingSummary.price.formatted
            }}</span>
          </div>
        </template>
      </div>
      <div class="tile__preview-container marking-tile__preview-container">
        <img v-if="marking.productImage" :src="marking.productImage" class="marking-tile__preview-container__image">
        <svg v-else>
          <use xlink:href="../assets/icons/sprite.svg#marking"></use>
        </svg>
      </div>
    </div>
  </router-link>
</template>

<script src="./MarkingTile.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./MarkingTile.scss";
</style>
