import { AccountStore } from "@/store/accountstate";
import { defineComponent } from "vue";
import { useRouter, RouteParams } from "vue-router"

const GoBack = defineComponent({
    name: "GoBack",
    props: {
        title: {
            type: String,
            required: true
        },
        routeName: {
            type: String,
            required: true
        },
        routeParams:{
          type: Object as () => RouteParams,
          required:false
        },
        showSettings:{
          type: Boolean,
          default: false
        }
    },
    emits: [
      "click"
    ],
    setup(props, context) {
        const router = useRouter();

        function getRouteName(): string {
            if (AccountStore.currentUser != null && AccountStore.currentUser.internalSalesSven && props.routeName === "orders") {
                return "searchorders";
            }
            return props.routeName
        }
        
        const goBack = async () =>  {
          context.emit("click");
          const routeName = getRouteName();
          await router.push({ name: routeName, params: props.routeParams});
        }

        return {
            goBack
        };
    }

});

export default GoBack;
