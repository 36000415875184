<template>
  <div class="container-with-side-img">
    <side-image :hideOnMobileAndTablet="true"/>
    <div class="help ">
      <go-back :title="$t('Manage:Help')" routeName="account" />
      <div v-if="content" v-html="content" class="content__container container-limited--side-img"></div>
    </div>

  </div>
</template>

<script src="./Help.ts">

</script>

<style lang="scss" scoped>
    @import './Help.scss';
</style>
