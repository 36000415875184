import { defineComponent, ref } from "vue";
import { Folder } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import PreviewOrderMarkingView from "@/components/PreviewOrderMarkingView.vue";

export default defineComponent({

    name: "PreviewOrderFolderView",

    components: {
        PreviewOrderMarkingView
    },

    props: {
        folder: {
            type: Object as () => Folder,
            required: true
        }
    },

    setup() {
        return {
            orderId: ref(OrderStore.order?.id)
        };
    }
});
