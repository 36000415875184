<template>
  <div class="folder-tree__item">
    <div class="folder-tree__link preview-order-tree__marking">
      <div class="preview-order-tree__marking-info-container">
        <div class="preview-order-tree__marking-info">
          <div class="preview-order-tree__marking-heading">
            <img
              src="../assets/icons/marking.svg"
              class="custom-svgs preview-order-tree__link-icon"
              alt=""
            />
            <h4 class="folder-tree__heading--big">{{ marking.name }}</h4>
          </div>

          <div class="preview-order-tree__marking-details">
            <template v-if="orderLine">
              <div>{{ orderLine.productName }}</div>
              <div>
                {{ getSewingTypeName(orderLine.curtainData.sewingType) }}
                {{ getOrientationName(orderLine.curtainData.orientation) }}
              </div>
              <div>               
                  {{ getUnderHemName(orderLine.curtainData.underHem) }}
                </div>
            </template>
          </div>
        </div>

        <div>
          <img v-if="markingImage" :src="markingImage" class="preview-order-tree__marking-image">
        </div>
      </div>

      <table
        v-if="orderLine"
        class="preview-order-tree__table"
        v-bind:class="{ open: active }"
      >
        <thead class="preview-order-tree__table-heading" @click="toggleTable()">
          <tr>
            <th class="preview-order-tree__arrow">
              {{ orderLine.curtainData.totalCurtainDropQuantity }}
              {{ translate("PreviewOrder:CurtainDropsListHeadingQtyMain") }} 
              
              <span v-if="orderLine.curtainConfiguration == 1 && orderLine.markingQuantity > 1">
              x {{orderLine.markingQuantity}} {{translate("Marking:PiecesShort")}}
              </span>
              <span v-else-if="orderLine.curtainConfiguration == 1">
              x 1 {{translate("Marking:PiecesShort")}}
              </span>
            </th>
            <th class="hide-mobile hide-tablet text-center">
              <span v-show="active">{{
                translate("PreviewOrder:CurtainDropsListHeadingFinishedHeight")
              }}
              </span>
            </th>
            <th class="hide-mobile hide-tablet text-center">
              <span v-show="active">
              {{
                translate("PreviewOrder:CurtainDropsListHeadingFinishedWidth")
              }}
              </span>
            </th>
            <th class="preview-order-tree__table-heading--price">
              {{ orderLine.price.formatted }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(curtainDrop, index) in orderLine.curtainData.curtainDrops"
            :key="curtainDrop.quantity"
          >
            <td>
              <div>{{ getItemText(index, curtainDrop.quantity, orderLine.curtainData.curtainDrops[0].quantity)}}</div>
              <div class="hide-desktop">{{translate("ConfiguratorEditor:OutputWidthLabel")}} {{ $filters.roundDecimals(curtainDrop.finishedWidthPerCurtainDrop) }} x {{translate("ConfiguratorEditor:OutputHeightLabel")}} {{ $filters.roundDecimals(curtainDrop.finishedHeightPerCurtainDrop)}} </div>
            </td>
            <td class="hide-mobile hide-tablet text-center">
              {{ $filters.roundDecimals(curtainDrop.finishedHeightPerCurtainDrop) }}
            </td>
            <td class="hide-mobile hide-tablet text-center">
              {{ $filters.roundDecimals(curtainDrop.finishedWidthPerCurtainDrop) }}
            </td>
            <td class="preview-order-tree__table-heading--price">
              {{ curtainDrop.pricePerCurtainDrop.formatted }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script src="./PreviewOrderMarkingView.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./PreviewOrderTreeView.scss";
</style>
