<template>
  <div class="edit-order container-with-tree">
    <div class="edit-order__content-container">
      <div class="edit-order__go-back-container">
        <go-back :title="$t('EditOrder:OrderSettings')" routeName="order" />
      </div>
      <router-link
        :to="{ name: 'order' }"
        class="btn__round btn__with-icon--close btn__with-icon edit-order__close"
      ></router-link>
      <h1 class="edit-order__header">{{ $t("EditOrder:OrderSettings") }}</h1>
      <Form @submit="saveOrder" id="editOrderForm">
        <div class="input__container">
          <label class="input__label edit-order__label" for="orderName">{{
            $t("EditOrder:OfferName")
          }}</label>
          <Field
            class="input__size--large input__icon input__icon--folder"
            type="text"
            maxlength="30"
            id="orderName"
            name="offerName"
            as="input"
            v-model="offerName"
            rules="required"
            autocomplete="off"
          />
          <ErrorMessage class="input__error-msg" name="offerName" />
        </div>
        <div class="input__container">
          <label class="input__label edit-order__label" for="offerNote">{{
            $t("EditOrder:OfferNote")
          }}</label>
          <Field
            id="offerNote"
            name="offerNote"
            as="textarea"
            v-model="order.note"
            autocomplete="off"
          />
        </div>
      </Form>
       </div>
      <div
        class="wizard-bottom-bar__container edit-order__wizard-bottom-container"
      >
        <button
          type="submit"
          form="editOrderForm"
          class="btn__default--primary edit-order__save-btn"
        >
          {{ $t("EditOffer:UpdateOrder") }}
        </button>
      </div>
   
  </div>
</template>

<script src="./EditOrder.ts" lang="ts"></script>


<style lang="scss" scoped>
@import "./EditOrder.scss";
</style>