<template>
  <div class="container-with-side-img">
    <side-image />

    <!-- List orders -->
    <template v-if="!isDeleteMode">
      <div class="order__container-outer">
        <div class="order__container-inner">
          <h1 class="order__header">{{ $t("Offers:Heading") }}</h1>
          <div class="tab__container order__tab-container">
            <div>
              <button
                v-for="status in orderStatuses"
                :key="status"
                v-on:click="activate(status)"
                v-bind:class="{ active: isActive(status) }"
                v-text="$t(labels.get(status))"
                class="tab__title"
              ></button>
            </div>
            <button
              v-on:click="loadOrdersForDelete()"
              class="btn__link order__edit-btn"
            >
              {{ $t("Offers:DeleteOrderButtonText") }}
            </button>
          </div>

          <div
            v-for="status in orderStatuses"
            :key="status"
            class="list__container container-limited--side-img"
          >
            <div v-if="isActive(status)">
              <ul class="list">
                <li v-if="status === 0" class="add-item">
                  <router-link :to="{ name: 'createOrder' }">
                    <div class="add-item__div">
                      <img
                        src="../../assets/icons/add.svg"
                        class="custom-svgs"
                        alt=""
                      />
                    </div>
                  </router-link>
                </li>
                <li
                  class="list__item"
                  v-for="order in orders.get(status)"
                  :key="order.offerNumber"
                >
                  <router-link
                    :to="{
                      name: getRouteByStatus(status),
                      params: { order: order.id },
                    }"
                    class="order__list-link"
                  >
                    <div class="list__content">
                      <svg class="custom-svgs">
                        <use
                          xlink:href="../../assets/icons/sprite.svg#offert"
                        ></use>
                      </svg>
                      <span>
                        #{{ order.offerNumber }} {{ order.offerName }}
                        <span class="shared-order">{{
                          getUserOrderSharedText(order.userEmailAddress)
                        }}</span>
                      </span>
                    </div>
                    <img
                      src="../../assets/icons/chevron-right.svg"
                      class="custom-svgs"
                      alt=""
                    />
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer__container order__footer-container">
          <div class="footer__invisible-div" />

          <div class="footer__new-order-container">
            <router-link :to="{ name: 'createOrder' }">
              <button class="btn__default--primary">
                {{ $t("Offers:NewOfferButtonText") }}
              </button>
            </router-link>
          </div>

          <div class="footer__profile-container">
            <router-link :to="{ name: 'account' }">
              <button class="btn__round profile__btn">
                <svg class="svg-icon custom-svgs" viewBox="0 0 20 20">
                  <path
                    d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"
                  ></path>
                </svg>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <!-- delete orders -->
    <template v-if="isDeleteMode">
      <div class="order__container-outer">
        <div class="order__container-inner">
          <h1 class="order__header">{{ $t("Offers:Heading") }}</h1>
          <div class="tab__container delete-order__btn-container">
            <div class="hide" />
            <button
              v-on:click="ordersDeleteCancel()"
              class="btn__link order__edit-btn"
            >
              {{ $t("Offers:DeleteCancelText") }}
            </button>
          </div>
          <div class="list__container container-limited--side-img">
            <ul class="list delete-order__list">
              <li
                class="list__item"
                v-for="order in ordersForDelete"
                :key="order.offerNumber"
              >
                <label class="checkbox__input-container delete-order__checkbox">
                  <input
                    type="checkbox"
                    v-bind:value="order.id"
                    v-model="selectedOrdersToDelete"
                  />
                  <span> #{{ order.offerNumber }} {{ order.offerName }} </span>
                  <span class="checkbox__checkmark"></span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer__container order__footer-container">
          <div class="footer__new-order-container">
           <button
              :disabled="!enableDeleteOrderButton()"
              v-on:click="ordersDeleteConfirm()"
              class="btn__link"
            >
              {{ $t("Offers:DeleteConfirmText") }}
            </button>
          </div>

        </div>
      </div>
    </template>
  </div>
</template>

<script src="./OrderList.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./OrderList.scss";
</style>
