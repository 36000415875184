import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router"
import { OrderStore } from "@/store/orderstate";
import { Order, Marking, MarkingImage } from "@/models/ordermodels";
import { OrderService } from "@/services/OrderService";
import { ImageService } from "@/axios/imageservice";
import { UIStore } from "@/store/ui";

export default defineComponent({

    name: "CreateMarkingImage",

    setup() {
        const router = useRouter();
        const order = computed(() => OrderStore.order as Order);
        const marking = computed(() => OrderStore.marking as Marking);
        const markingImage = ref<MarkingImage>({} as MarkingImage);
        const message = ref('');
        const file = ref<File | null>();
        const form = ref<HTMLFormElement>();

        function onFileChanged($event: Event) {
            const target = $event.target as HTMLInputElement;
            if (target && target.files) {
                message.value = "";
                markingImage.value.fileId = "";
                markingImage.value.thumbnailFileId = "";
                file.value = target.files[0];
            }
        }

        async function saveImage() {
            if (file.value) {
                try {
                    UIStore.setLoading(true);
                    await ImageService.createMarkingImage({
                        file: file.value,
                        createThumbnail: true,
                        name: markingImage.value.name,
                        note: markingImage.value.note,
                        markingId: marking.value.id,
                    });
                    await OrderService.loadOrder(order.value.id);
                    router.push({ name: "marking-image-list", params: { order: order.value.id, marking: marking.value.id } });
                } catch (error) {
                    console.error(error);
                    message.value = "MarkingImageUpload:ErrorMessage";
                    form.value?.reset();
                    file.value = null;
                    markingImage.value = {} as MarkingImage;
                } finally {
                    UIStore.setLoading(false);
                }
            }
        };

        return {
            markingImage,
            form,
            saveImage,
            onFileChanged,
            message,
            marking,
            order,
        }
    }
});
