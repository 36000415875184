import { defineComponent, computed } from "vue";
import { Folder } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import MarkingTreeView from "./MarkingTreeView.vue";
import { ChangeStore } from "@/store/changestate";

export default defineComponent({

    name: "FolderTreeView",

    components: {
        MarkingTreeView
    },

    props: {
        folder: {
            type: Object as () => Folder,
            required: true,
        },
        folderSelectionEnabled: {
            type: Boolean,
            required: false,
        },
        folderEditEnabled: {
            type: Boolean,
            required: false,
        },
        markingRoute: {
            type: String,
            required: true,
        },
    },

    setup(props) {
      const orderId: string | undefined = OrderStore.order?.id;
      const editMode = computed<boolean>(() => ChangeStore.editMode && props.folder.parentId !== null);

      const selectForEditing = (e: InputEvent): void => {
        const target = (e.target as HTMLInputElement);
        ChangeStore.saveFolderState({ id: props.folder.id, checked: target.checked, name: props.folder.name });
      };

      return {
          orderId: orderId,
          editMode: editMode,
          selectForEditing: selectForEditing
      };
    }
});
