import { BareActionContext, getStoreBuilder } from "vuex-typex";
import { RootState } from "./";

// state
class State {
    loading: number = 0;
};

const store = getStoreBuilder<RootState>().module("ui", new State());

// getters
const getLoading = store.read((state) => state.loading > 0, "loading");

// mutations
const mutations = {
    setLoading: async function (state: State, payload: boolean = true): Promise<void> {
        state.loading = Math.max(0, state.loading + (payload ? 1 : -1));
    }
};

// actions
async function setLoading(context: BareActionContext<State, RootState>, payload: boolean): Promise<void> {
    return await mutations.setLoading(context.state, payload);
}

export const UIStore = {
    get loading(): boolean { return getLoading() },
    setLoading: store.dispatch(setLoading),
} as const;
