import axios from "./index";

export const UserOrderSharingApi = {
    async add(emailAddress: string): Promise<void> {
        return await axios.post("/api/app/userordersharing/userordersharing", { emailAddress: emailAddress });
    },
    async get(): Promise<string[]> {
        return await axios.get(`/api/app/userordersharing/userordersharing`);
    },
    async delete(emailAddress: string): Promise<void> {
        return await axios.delete(`/api/app/userordersharing/userordersharing?emailAddress=${emailAddress}`);
    }
}
