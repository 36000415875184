<template>
  <div v-if="order" class="order-completed">
    <div class="order-completed__complete-symbol">
      <svg class="custom-svgs">
        <use xlink:href="../../assets/icons/sprite.svg#checkmark"></use>
      </svg>
    </div>
    <h1>{{ state.heading }}</h1>
    <span class="order-completed__order-nr"
      >{{ $t("PreviewOffer:OfferNumber") }}: {{ order.offerNumber }}</span
    >
    <router-link
      :to="{ name: state.routerLinkRouteName }"
      class="btn__default--primary order-completed__route-back"
      >{{ state.routerLinkText }}
    </router-link>
  </div>
</template>

<script src="./OrderCompleted.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./OrderCompleted.scss";
</style>
