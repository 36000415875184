import { Options, Vue } from "vue-class-component";
import { AccountService } from "@/axios/accountservice";
import { CurrentUser, Token } from "@/models/accountmodels";
import { AccountStore } from "@/store/accountstate";
import SideImage from '@/components/sideImage/SideImage.vue'
import GoBack from '@/components/goBack/GoBack.vue'

@Options({
    name: 'Manage',
    components: {
        SideImage,
        GoBack,
    }
})
export default class Manage extends Vue {
    protected errorMessage = "";
    protected successMessage = "";

    protected get currentUser(): CurrentUser | null {
        return AccountStore.currentUser;
    }

    protected set currentUser(user: CurrentUser | null) {
        AccountStore.saveUser(user);
    }

    protected get showUserOrderSharing(): boolean {
        return !AccountStore.currentUser?.internalSalesSven ?? false;
    }

    private get currentToken(): Token | null {
        return AccountStore.currentToken;
    }

    private set currentToken(token: Token | null) {
        AccountStore.saveToken(token);
    }

    protected onSignOut(): void {
        AccountService.signOut().then(() => {
            this.currentUser = null;
            this.currentToken = null;
            this.$router.push({name: "login"});
        });
    }
}
