<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { SAOPass } from "three/examples/jsm/postprocessing/SAOPass";
import { SSAARenderPass } from "three/examples/jsm/postprocessing/SSAARenderPass";

import * as dat from "dat.gui";

export default {
  name: "Curtain",
  data: () => ({
    needsUpdate: false,
    hasSewingTypeUpdated: false,
    hasTextureUpdated: false,
    curtainResolution: 2,
    curtain: {
      width: 0,
      height: 0,
      sewingType: 0,
      splitCurtain: false,
    },
    texture: {
      size: 0,
      url: null,
      normalUrl: null,
    },
    hasWindowSizeChanged: true,
  }),
  props: {
    textureUrl: {
      type: [String],
      default: () => null,
    },
    normalMapUrl: {
      type: [String],
      default: () => null,
    },
    curtainWidth: {
      type: [Number],
      default: () => 30,
    },
    curtainHeight: {
      type: [Number],
      default: () => 30,
    },
    textureSize: {
      type: [Number],
      default: () => 10,
    },
    sewingType: {
      type: [Number],
      default: () => 0,
    },
    splitCurtain: {
      type: [Boolean],
      default: () => false,
    },
  },
  watch: {
    curtainWidth: function (value) {
      this.curtain.width = this.calculateWidthLoss(value);
      this.needsToUpdate();
    },
    curtainHeight: function (value) {
      this.curtain.height = value;
      this.needsToUpdate();
    },
    textureSize: function (value) {
      this.texture.size = value;
      this.needsToUpdate();
    },
    textureUrl: function (value) {
      this.texture.url = value;
      this.needsToUpdate();
      this.textureUpdated();
    },
    normalMapUrl: function (value) {
      this.texture.normalUrl = value;
      this.needsToUpdate();
      this.textureUpdated();
    },
    sewingType: function (value) {
      this.curtain.sewingType = value;
      this.sewingTypeUpdated();
      this.needsToUpdate();
    },
    splitCurtain: function (value) {
      this.curtain.splitCurtain = value;
      this.needsToUpdate();
    },
  },
  methods: {
    needsToUpdate: function () {
      this.needsUpdate = true;
    },
    textureUpdated: function () {
      this.hasTextureUpdated = true;
    },
    sewingTypeUpdated: function () {
      this.hasSewingTypeUpdated = true;
    },
    setInitialValues: function () {
      this.curtain.width = this.calculateWidthLoss(this.curtainWidth);
      this.curtain.height = this.curtainHeight;
      this.curtain.sewingType = this.sewingType;
      this.curtain.splitCurtain = this.splitCurtain;
      this.texture.size = this.textureSize;
      this.texture.url = this.textureUrl;
      this.texture.normalUrl = this.normalMapUrl;
    },
    resizeEventHandler: function () {
      this.hasWindowSizeChanged = true;
    },
    calculateWidthLoss: function (width) {
      let newWidth = width;

      switch (this.curtain.sewingType) {
        case 0:
          // Wave80
          newWidth = Math.floor(newWidth / 2.1);
          break;

        case 1:
          // Wave60
          newWidth = Math.floor(newWidth / 1.91);
          break;

        case 2:
          // GliderSinglePleat
          newWidth = Math.floor(newWidth / 1.4);
          break;

        case 3:
          // CurtainHeaderTape
          newWidth = Math.floor(newWidth / 1.4);
          break;

        case 4:
          // Glider
          newWidth = Math.floor(newWidth / 1.3);
          break;

        default:
          break;
      }

      return newWidth;
  },
  },
  created() {
    window.addEventListener("resize", this.resizeEventHandler, false);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeEventHandler, false);
  },
  mounted() {
    this.setInitialValues();

    let canvas = this.$refs.canvas,
        canvasRenderer,
        renderer,
        scene,
        camera,
        composer,
        textureLoader,
        gui;

    let general = {
      framerate: 25,
      allPromises: [],
    };

    let lights = {
      intensity: 1.5,
      brightColor: 0xf5f4ef,
      darkColor: 0x2b2b2a,
      shadowBias: -0.00001,
      ambientLight: null,
      mainLight: null,
    };

    let curtain = {
      meshes: [],
      geometry: null,
      material: null,
      spacing: 2,
      waveAmplitude: 1,
      waveFrequency: 1,
      pulsePeriod: 4,
      pulseOscillation: 0.5,
      waveFalloff: -100,
      widthLoss: 0,
      zOffset: -13,
      texture: {
        albedo: null,
        normal: null,
        width: 0,
        height: 0,
        ratio: {
          x: 0,
          y: 0,
        },
      },
      textureId: 0,
      materials: [],
      waveform: "sine",
      shadow: {
        meshes: [],
        geometry: null,
        material: null,
        texture: null,
        padding: 10,
        height: 10,
        opacity: 1,
        offset: 1,
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      hanger: {
        mesh: null,
        geometry: null,
        material: null,
        size: {
          height: 0.2,
          thickness: 0.1,
          padding: 1,
        },
        color: 0xffffff,
        roughness: 0.3,
        metalness: 0.5,
      },
    };

    let cameraSettings = {
      fov: 75,
      aspect: 2,
      near: 1,
      far: 250,
      margin: 5,
    };

    let postFX = {
      sao: {
        pass: null,
        saoBias: 1,
        saoIntensity: 0.08,
        saoScale: 8,
        saoKernelRadius: 30,
        saoMinResolution: 0,
      },
      exposure: 1.05,
    };

    let devTools = {
      drawRaycasts: true,
      useOrbitalControls: true,
      useGUI: false,
      usePostProcessingGUI: false,
    };

    const init = () => {
      // Setup
      setupScene();
      addLights();
      loadSceneTextures();

      // Presets
      setSewingType();

      // Curtain
      loadCurtainTexture();

      // Post
      postProcessing();

      // Tools
      if (devTools.useOrbitalControls) {
        addOrbitControls();
      }

      if (devTools.useGUI) {
        addGUI();
      }

      if (devTools.usePostProcessingGUI) {
        addPostProcessingGUI();
      }
    };

    // Setup
    const setupScene = () => {
      scene = new THREE.Scene();
      scene.background = 0xffffff;
      camera = new THREE.PerspectiveCamera(
        cameraSettings.fov,
        cameraSettings.aspect,
        cameraSettings.near,
        cameraSettings.far
      );

      renderer = new THREE.WebGLRenderer({
        canvas,
        antialias: false,
        alpha: true,
      });

      canvasRenderer = renderer.domElement;

      renderer.setSize(canvasRenderer.clientWidth, canvasRenderer.clientHeight);

      // Shadow Settings
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      renderer.shadowMap.shadowSide = THREE.DoubleSide;
      renderer.toneMapping = THREE.ReinhardToneMapping;
      renderer.toneMappingExposure = Math.pow(postFX.exposure, 4.0);

      renderer.setPixelRatio(window.devicePixelRatio);

      // camera.position.x = cameraSettings.position.x;
      // camera.position.y = cameraSettings.position.y;
    };

    const addLights = () => {
      lights.ambientLight = new THREE.HemisphereLight(
        lights.brightColor,
        lights.darkColor,
        lights.intensity
      );

      scene.add(lights.ambientLight);

      lights.mainLight = new THREE.SpotLight(
        lights.brightColor,
        lights.intensity
      );
      lights.mainLight.position.set(-50, 50, 50);
      lights.mainLight.castShadow = true;
      lights.mainLight.shadow.bias = lights.shadowBias;
      lights.mainLight.shadow.mapSize.width = 1024 * 4;
      lights.mainLight.shadow.mapSize.height = 1024 * 4;

      scene.add(lights.mainLight);
    };

    // Curtain
    const createCurtainMaterials = () => {
      curtain.materials = [];

      // Albedo Texture
      curtain.texture.albedo.wrapS = THREE.RepeatWrapping;
      curtain.texture.albedo.wrapT = THREE.RepeatWrapping;

      curtain.material = new THREE.MeshPhongMaterial({
          map: curtain.texture.albedo,
          shininess: 5,
          flatShading: false,
      });

      if (this.texture.normalUrl) {
        // Normal Map
        curtain.texture.normal.wrapS = THREE.RepeatWrapping;
        curtain.texture.normal.wrapT = THREE.RepeatWrapping;

        curtain.material.normalMap = curtain.texture.normal;
      }

      updateTextureRepeat();

      curtain.materials.push(curtain.material);

      curtain.materials.push(
        new THREE.MeshPhongMaterial({
            color: 0x683733,
            shininess: 5,
            flatShading: true,
        })
      );

      curtain.materials.push(
        new THREE.MeshPhongMaterial({
            color: 0x683733,
            flatShading: false,
        })
      );

      curtain.materials.push(
        new THREE.MeshPhongMaterial({
            color: 0x000000,
            wireframe: true,
        })
      );
    };

    const updateTextureRepeat = () => {
      if (!curtain.material) return;

      let textureHeightRatio = Number(
        (1 * (curtain.texture.height / curtain.texture.width)).toFixed(2)
      );
      let curtainWidthRatio = Number(
        (1 * (this.curtain.width / this.curtain.height)).toFixed(2)
      );

      let widthRepeat =
        Number(textureHeightRatio * curtainWidthRatio).toFixed(2) *
        (this.texture.size * (this.curtain.height / 100));
      let heightRepeat = 1 * (this.texture.size * (this.curtain.height / 100));

      curtain.material.map.repeat.set(widthRepeat, heightRepeat);

      if (curtain.texture.normal) {
        curtain.material.normalMap.repeat.set(widthRepeat, heightRepeat);
    }
    };

    const createHanger = () => {
      if (curtain.hanger.mesh !== null) {
        scene.remove(curtain.hanger.mesh);
      }

      let hangerWidth = this.curtain.width;

      if (this.curtain.splitCurtain) {
        hangerWidth += curtain.spacing * 2;
      }

      curtain.hanger.geometry = new THREE.BoxGeometry(
        hangerWidth,
        curtain.hanger.size.height,
        curtain.hanger.size.thickness
      );

      curtain.hanger.material = new THREE.MeshStandardMaterial({
        roughness: curtain.hanger.roughness,
        metalness: curtain.hanger.metalness,
        color: curtain.hanger.color,
      });

      curtain.hanger.mesh = new THREE.Mesh(
        curtain.hanger.geometry,
        curtain.hanger.material
      );

      curtain.hanger.mesh.position.y =
        this.curtain.height / 2 + curtain.hanger.size.height / 2;
      curtain.hanger.mesh.castShadow = true;
      curtain.hanger.mesh.receiveShadow = true;

      scene.add(curtain.hanger.mesh);
    };

    const createCurtain = () => {
      // Remove old meshes
      scene.remove(curtain.meshes[0]);
      scene.remove(curtain.meshes[1]);
      curtain.meshes = [];

      // Set instance of width to not overwrite default
      let recalculatedWidth = this.curtain.width;

      if (this.curtain.splitCurtain) {
        cameraSettings.margin = 9;
        recalculatedWidth = Math.floor(this.curtain.width / 2);

        if (recalculatedWidth < 1) {
          recalculatedWidth = 1;
        }
      } else {
        cameraSettings.margin = 5;
      }

      // Create a new mesh with updated properties
      curtain.geometry = new THREE.PlaneGeometry(
        recalculatedWidth,
        this.curtain.height,
        recalculatedWidth * this.curtainResolution,
        this.curtain.height * this.curtainResolution
      );

      // Add extra Curtain if split
      if (this.curtain.splitCurtain) {
        curtain.meshes.push(
          new THREE.Mesh(curtain.geometry, curtain.materials[curtain.textureId])
        );
      }

      curtain.meshes.push(
        new THREE.Mesh(curtain.geometry, curtain.materials[curtain.textureId])
      );

      setCurtainWaveform(curtain.waveform);

      curtain.meshes.map((mesh) => {
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        // Add new mesh to scene
        scene.add(mesh);
      });

      if (this.curtain.splitCurtain) {
        curtain.meshes[0].position.x =
          -(recalculatedWidth / 2) - curtain.spacing;
        curtain.meshes[1].position.x = recalculatedWidth / 2 + curtain.spacing;
      }

      createFloorShadow();
      updateVertexNormals();
      createHanger();
    };

    const createFloorShadow = () => {
      // Remove old meshes
      scene.remove(curtain.shadow.meshes[0]);
      scene.remove(curtain.shadow.meshes[1]);
      curtain.shadow.meshes = [];

      // Set instance of width to not overwrite default
      let shadowWidth = this.curtain.width * 4;

      if (this.curtain.splitCurtain) {
        shadowWidth = Math.floor(shadowWidth / 2) - curtain.spacing;
      }

      curtain.shadow.geometry = new THREE.PlaneGeometry(
        shadowWidth,
        this.curtain.height * 4,
        1,
        1
      );

      curtain.shadow.material = new THREE.MeshBasicMaterial({
        map: curtain.shadow.texture,
        transparent: true,
        depthWrite: false,
        depthTest: true,
        opacity: curtain.shadow.opacity,
      });

      // Add extra Curtain if split
      if (this.curtain.splitCurtain) {
        curtain.shadow.meshes.push(
          new THREE.Mesh(curtain.shadow.geometry, curtain.shadow.material)
        );
      }

      curtain.shadow.meshes.push(
        new THREE.Mesh(curtain.shadow.geometry, curtain.shadow.material)
      );

      curtain.shadow.meshes[0].position.y = -(
        this.curtain.height / 2 +
        curtain.shadow.offset
      );
      curtain.shadow.meshes[0].rotation.x = -(Math.PI / 2);

      if (this.curtain.splitCurtain) {
        curtain.shadow.meshes[0].position.x =
          -(shadowWidth / 8) - curtain.spacing;

        curtain.shadow.meshes[1].position.y = -(
          this.curtain.height / 2 +
          curtain.shadow.offset
        );
        curtain.shadow.meshes[1].rotation.x = -(Math.PI / 2);
        curtain.shadow.meshes[1].position.x = shadowWidth / 8 + curtain.spacing;
      }

      curtain.shadow.meshes.map((mesh) => {
        scene.add(mesh);
      });
    };

    // Tools
    const addOrbitControls = () => {
      const orbitControls = new OrbitControls(camera, canvasRenderer);
      orbitControls.target.set(0, 0, 0);
      orbitControls.update();
    };

    const addGUI = () => {
      gui = new dat.GUI();
      gui.domElement.id = "gui";

      let curtainSettings = gui.addFolder("Curtain Settings");

      curtainSettings
        .add(this.curtain, "width")
      .min(1)
      .max(100)
      .step(1)
        .name("Width")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(this.curtain, "height")
      .min(1)
      .max(100)
      .step(1)
        .name("Height")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(this, "curtainResolution")
      .min(1)
      .max(4)
      .step(1)
        .name("Resolution")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(curtain, "textureId", {
          "Test Texture": 0,
          "Flat Shading": 1,
          "Smooth shading": 2,
          Wireframe: 3,
      })
        .name("Curtain Texture")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(this.texture, "size")
      .min(0.01)
      .max(100.0)
      .step(0.01)
        .name("Texture Scale")
      .onChange(() => {
        createCurtainMaterials();
        this.needsUpdate = true;
      });

      curtainSettings
        .add(curtain, "waveform", {
          Sine: "sine",
          Pinch: "pinch",
          Pulse: "pulse",
      })
        .name("Curtain Waveform")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(curtain.shadow, "opacity")
      .min(0)
      .max(1.0)
      .step(0.1)
        .name("Shadow Opacity")
      .onChange(() => {
        createFloorShadow();
        this.needsUpdate = true;
      });

      curtainSettings
        .add(curtain, "waveAmplitude")
      .min(0)
      .max(2.0)
      .step(0.1)
        .name("Wave Amplitude")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(curtain, "waveFrequency")
      .min(0.01)
      .max(3)
      .step(0.01)
        .name("Wave Frequency")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(curtain, "pulsePeriod")
      .min(0.1)
      .max(10)
      .step(0.5)
        .name("Pulse Period")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(curtain, "waveFalloff")
      .min(-100)
      .max(100)
      .step(0.1)
        .name("Wave Falloff")
      .onChange(() => {
        this.needsUpdate = true;
      });

      curtainSettings
        .add(curtain, "zOffset")
      .min(-100)
      .max(100)
      .step(0.1)
        .name("zOffset")
      .onChange(() => {
        this.needsUpdate = true;
      });
    };

    const addPostProcessingGUI = () => {
      // SAO Settings
      let saoGui = gui.addFolder("SAO Settings");

      saoGui
        .add(postFX.sao.pass.params, "output", {
          Beauty: SAOPass.OUTPUT.Beauty,
          "Beauty+SAO": SAOPass.OUTPUT.Default,
          SAO: SAOPass.OUTPUT.SAO,
          Depth: SAOPass.OUTPUT.Depth,
          Normal: SAOPass.OUTPUT.Normal,
        })
        .onChange(function (value) {
        postFX.sao.pass.params.output = parseInt(value);
      });

      saoGui.add(postFX.sao.pass.params, "saoBias", -1, 1);
      saoGui.add(postFX.sao.pass.params, "saoIntensity", 0, 1);
      saoGui.add(postFX.sao.pass.params, "saoScale", 0, 10);
      saoGui.add(postFX.sao.pass.params, "saoKernelRadius", 1, 100);
      saoGui.add(postFX.sao.pass.params, "saoMinResolution", 0, 1);
      saoGui.add(postFX.sao.pass.params, "saoBlur");
      saoGui.add(postFX.sao.pass.params, "saoBlurRadius", 0, 200);
      saoGui.add(postFX.sao.pass.params, "saoBlurStdDev", 0.5, 150);
      saoGui.add(postFX.sao.pass.params, "saoBlurDepthCutoff", 0.0, 0.1);
    };

    const getCameraPositionZ = (
      curtainWidth,
      curtainHeight,
      cameraFov,
      cameraAspect,
      margin
    ) => {
      const vFOV = THREE.MathUtils.degToRad(cameraFov);
      const targetViewHeight = Math.max(
        curtainWidth / cameraAspect,
        curtainHeight
      );

      return targetViewHeight / (2 * Math.tan(vFOV / 2)) + margin;
    };

    // Post Processing
    const postProcessing = () => {
      composer = new EffectComposer(renderer);

      // Render Pass
      let renderPass = new RenderPass(scene, camera);
      composer.addPass(renderPass);

      // SSAA Render Pass
      let ssaaRenderPass = new SSAARenderPass(scene, camera, 0x000000, 0);
      ssaaRenderPass.setSize(
        canvasRenderer.clientWidth,
        canvasRenderer.clientHeight
      );
      ssaaRenderPass.unbiased = true;
      ssaaRenderPass.sampleLevel = 2;
      composer.addPass(ssaaRenderPass);

      // SAO Pass
      postFX.sao.pass = new SAOPass(
        scene,
        camera,
        false,
        true,
        new THREE.Vector2(1024, 1024)
      );
      postFX.sao.pass.params.saoBias = postFX.sao.saoBias;
      postFX.sao.pass.params.saoIntensity = postFX.sao.saoIntensity;
      postFX.sao.pass.params.saoScale = postFX.sao.saoScale;
      postFX.sao.pass.params.saoKernelRadius = postFX.sao.saoKernelRadius;
      postFX.sao.pass.params.saoMinResolution = postFX.sao.saoMinResolution;
      composer.addPass(postFX.sao.pass);
    };

    // Presets
    const setSewingType = () => {
      switch (this.curtain.sewingType) {
        case 0:
          // Wave80
          curtain.waveAmplitude = 0.2;
          curtain.waveFrequency = 0.25;
          curtain.waveform = "sine";
          curtain.waveFalloff = -100;
          break;

        case 1:
          // Wave60
          curtain.waveAmplitude = 0.4;
          curtain.waveFrequency = 0.14;
          curtain.waveform = "sine";
          curtain.waveFalloff = -100;
          break;

        case 2:
          // GliderSinglePleat
          curtain.waveAmplitude = 0.5;
          curtain.waveFrequency = 1.5;
          curtain.pulsePeriod = 2;
          curtain.waveform = "pulse";
          curtain.waveFalloff = -75;
          break;

        case 3:
          // CurtainHeaderTape
          curtain.waveAmplitude = 0.3;
          curtain.waveFrequency = 0.25;
          curtain.waveform = "pinch";
          curtain.waveFalloff = 4.5;
          curtain.zOffset = -2;
          break;

        case 4:
          // Glider
          curtain.waveAmplitude = 0.5;
          curtain.waveFrequency = 1.5;
          curtain.pulsePeriod = 2.5;
          curtain.waveform = "pulse";
          curtain.waveFalloff = -75;
          break;

        default:
          break;
      }
    };

    // Helpers
    const loadSceneTextures = () => {
      textureLoader = new THREE.TextureLoader();

      curtain.shadow.texture = textureLoader.load(
        require("@/assets/curtain-visualizer/Shadow_TOP_V1.png"),
          (texture) => {
            // onLoad — Will be called when load completes.
          console.log("Success: " + texture.image.currentSrc + " loaded.");
          },
          () => {}, // onProgress — Will be called while load progresses.
          (error) => {
            // onError — Will be called when load errors.
            console.log(error);
          }
      );
    };

    const loadCurtainTexture = () => {
      if (this.texture.url) {
        general.allPromises.push(
          new Promise((resolve, reject) => {
          textureLoader.load(
            this.texture.url,
            (texture) => {
              // onLoad — Will be called when load completes.
              curtain.texture.albedo = texture;
              curtain.texture.width = texture.image.width;
              curtain.texture.height = texture.image.height;

              resolve(texture);
            },
            () => {}, // onProgress — Will be called while load progresses.
            (error) => {
              // onError — Will be called when load errors.
              reject(new Error(error));
            }
          );
          })
        );
      } else {
        console.log("No curtain texture specified!");
      }

      if (this.texture.normalUrl) {
        general.allPromises.push(
          new Promise((resolve, reject) => {
          textureLoader.load(
            this.texture.normalUrl,
            (texture) => {
              // onLoad — Will be called when load completes.
              curtain.texture.normal = texture;

              resolve(texture);
            },
            () => {}, // onProgress — Will be called while load progresses.
            (error) => {
              // onError — Will be called when load errors.
              reject(new Error(error));
            }
          );
          })
        );
      } else {
        console.log("No curtain normal map specified!");
      }

      Promise.all(general.allPromises).then(
        function () {
        createCurtainMaterials();
        createCurtain();
          render();
        },
        function (error) {
        console.error("Could not load all textures:", error);
    }
      );
    };

    const setCurtainWaveform = function (waveform) {
      switch (waveform) {
        case "sine":
          sineWaves();
          break;

        case "pinch":
          pinchWave();
          break;

        case "pulse":
          pulseWave();
          break;

        default:
          break;
      }
    };

    const sineWaves = () => {
      let rows = [];
      let verticesX = this.curtain.width * this.curtainResolution + 1;
      let rowIndex = 0;

      curtain.meshes.map((mesh) => {
        mesh.geometry.vertices.map((v, i) => {
          if (i % verticesX === 0) {
            rowIndex++;
            rows[rowIndex] = [i];
          }
          // Wave pattern from a Sine Wave
          let wavePosZ =
            curtain.waveAmplitude * Math.sin(v.x / curtain.waveFrequency);
          v.z = wavePosZ;
        });
      });
    };

    const pinchWave = () => {
      let rows = [];
      let verticesX = this.curtain.width * this.curtainResolution + 1;
      let verticesY = this.curtain.height * this.curtainResolution + 1;
      let decreasePercentage = 1 / verticesY;
      let rowIndex = 0;

      curtain.meshes.map((mesh) => {
        mesh.geometry.vertices.map((v, i) => {
          if (i % verticesX === 0) {
            rowIndex++;
            rows[rowIndex] = [i];
          }

          // Wave pattern from a Sine Wave
          let wavePosZ =
            curtain.waveAmplitude *
            Math.exp(Math.sin(v.x / curtain.waveFrequency));
          wavePosZ += curtain.zOffset / 10; // offset the Z position

          // Waveyness decrease amount per row of vertices
          let decreaseAmount = decreasePercentage * rowIndex * wavePosZ;
          let newValue = wavePosZ - decreaseAmount;
          v.z = newValue;
        });
      });
    };

    const pulseWave = () => {
      let rows = [];
      let verticesX = this.curtain.width * this.curtainResolution + 1;
      let verticesY = this.curtain.height * this.curtainResolution + 1;
      let decreasePercentage = 1 / verticesY;
      let rowIndex = 0;

      curtain.meshes.map((mesh) => {
        mesh.geometry.vertices.map((v, i) => {
          if (i % verticesX === 0) {
            rowIndex++;
            rows[rowIndex] = [i];
          }

          let wavePosZ =
            Math.abs(v.x) % curtain.pulsePeriod < curtain.pulseOscillation
              ? 1
              : 0;
          let decreaseAmount = decreasePercentage * rowIndex * wavePosZ;
          let newValue = wavePosZ * curtain.waveAmplitude - decreaseAmount / 2;
          v.z = newValue;
        });
      });
    };

    const updateVertexNormals = () => {
      curtain.geometry.computeVertexNormals(true);
    };

    const update = () => {
      setTimeout(() => {
        requestAnimationFrame(update);
      }, 1000 / general.framerate);

      if (this.hasSewingTypeUpdated) {
        setSewingType();
        this.hasSewingTypeUpdated = false;
        render();
      }

      if (this.needsUpdate) {
        if (this.hasTextureUpdated) {
          loadCurtainTexture();
          this.hasTextureUpdated = false;
        }

        updateTextureRepeat();
        createCurtain();
        this.needsUpdate = false;
      render();
    }

      if (this.hasWindowSizeChanged) {
        if (renderer) {
          renderer.setSize(
            canvas.parentElement.clientWidth,
            canvas.parentElement.clientHeight
          );
        }
        if (composer) {
          composer.setSize(
            canvas.parentElement.clientWidth,
            canvas.parentElement.clientHeight
          );
        }

        camera.aspect =
          canvas.parentElement.clientWidth / canvas.parentElement.clientHeight;
        camera.updateProjectionMatrix();
        render();
        this.hasWindowSizeChanged = false;
      }
    };

    // Render
    const render = () => {
      camera.position.z = getCameraPositionZ(
        this.curtain.width,
        this.curtain.height,
        camera.fov,
        camera.aspect,
        cameraSettings.margin
      );
      composer.render();
    };

    init();
    update();
  },
};
</script>

<style scoped>
canvas {
    height: 100%;
    width: 100%;
    display: block;
  background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(221, 221, 221, 1) 40%,
    rgba(255, 255, 255, 1) 100%
    );
}
</style>
