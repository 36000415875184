<template>
    <div class="app__container">
        <router-view :key="orderId" name="sidebar" />
        <router-view :key="$route.fullPath" />
    </div>
  <backdrop />
</template>

<script src="./App.ts" lang="ts"></script>

<style lang="scss">
#app {
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgb(14, 51, 14);
    cursor: default;
}
.app__container {
    height: 100%;
    @include tabletdesktop {
        display: flex;
    }
}
</style>
