<template>
  <li class="folder-tree__list">
    <div class="folder-tree__item folder-tree__root folder-tree__link-container">
        <router-link v-if="folderSelectionEnabled && !editMode" :to="{ name: 'folderview', params: { order: orderId, folder: folder.id }}" class="folder-tree__link">
          <img src="../assets/icons/folder.svg" class="custom-svgs folder-tree__link-icon" alt="" />
          {{ folder.name }}
        </router-link>
        <span v-else class="folder-tree__link">
          <img v-if="!editMode" src="../assets/icons/folder.svg" class="custom-svgs folder-tree__link-icon" alt="" />
          <label class="checkbox__input-container checkbox-in-tree__container">
            <input type="checkbox" v-if="editMode" @change="selectForEditing($event)" />
            {{folder.name}}
            <span v-if="editMode" class="checkbox__checkmark checkbox-in-tree__checkmark"></span>
          </label>
        </span>
    </div>
    <ul v-if="folder.markings.length" class="folder-tree__markings">
      <li v-for="marking in folder.markings" :key="marking.id">
        <marking-tree-view :marking="marking" :route="markingRoute" />
      </li>
    </ul>
    <ul v-if="folder.folders.length" class="folder-tree__folders">
      <folder-tree-view v-for="f in folder.folders" :key="f.id" :folder="f" :folder-selection-enabled="folderSelectionEnabled" :folder-edit-enabled="true" :marking-route="markingRoute" />
    </ul>
  </li>
</template>

<script src="./FolderTreeView.ts" lang="ts"></script>
