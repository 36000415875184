import { computed, defineComponent, ref } from "vue";
import { OrderStore } from "@/store/orderstate";
import { onBeforeRouteUpdate } from "vue-router";

const ContextMenu = defineComponent({
    emits: [
        "context-click"
    ],

    methods: {
        toggle (_: MouseEvent, toggle?: boolean) {
            if (toggle === undefined) {
                this.active = !this.active
            } else {
                this.active = toggle;
            }
            this.$emit("context-click", this.active);
        },
    },

    setup () {
        const order = computed(() => OrderStore.order);
        const marking = computed(() => OrderStore.marking);
        const active = ref<boolean>(false);

        onBeforeRouteUpdate(() => {
            active.value = false;
        });

        return {
            order,
            marking,
            active,
        };
    },
});

export default ContextMenu;
