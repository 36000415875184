<template>
  <div class="container-with-side-img">
    <side-image />
    <div class="form__container" v-if="showMaintenancePage"> 
      <span>{{ $t("Login:MaintenanceMessage") }}</span>
    </div>

    <div class="form__container" v-if="!showMaintenancePage">
      <h1 class="login-header">{{ $t("Login:HeaderText") }}</h1>
      <p class="login-subheading">{{ $t("Login:SubHeadingText") }}</p>
      <Form @submit="onLogIn" v-if="!currentUser">
        <div class="input-fields__container">
          <div class="input__container">
            <label
              for="emailAddress"
              class="input__label--small input__label"
              >{{ $t("Login:EmailAddress") }}</label
            >
            <Field
              id="emailAddress"
              name="emailAddress"
              as="input"
              type="email"
              :label="$t('Login:EmailAddress')"
              rules="required|email"
              autocomplete="on"
              v-model="emailAddress"
            />
            <ErrorMessage name="emailAddress" class="input__error-msg" />
          </div>
          <div class="input__container">
            <label for="password" class="input__label--small input__label">{{
              $t("Login:Password")
            }}</label>
            <Field
              id="password"
              name="password"
              as="input"
              type="password"
              :label="$t('Login:Password')"
              rules="required"
              autocomplete="on"
              v-model="password"
            />
            <ErrorMessage name="password" class="input__error-msg" />
          </div>
          <div class="login__buttons-container">
            <router-link :to="{ name: 'forgotpassword' }">{{
              $t("ForgotPassword:ForgotPassword")
            }}</router-link>

            <button class="btn__default--primary" type="submit">
              {{ $t("Login:SignIn") }}
            </button>
          </div>
          <p
            v-if="errorMessage.length > 0"
            class="additional-info__msg msg__error"
          >
            {{ errorMessage }}
          </p>

          <p
            v-if="successMessage.length > 0"
            class="additional-info__msg msg__success"
          >
            {{ successMessage }}
          </p>
        </div>
      </Form>
    </div>
  </div>
</template>

<script src="./Login.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./Login.scss";
</style>
