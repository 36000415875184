import axios from "@/axios";
import { CreateFolder, Folder } from "@/models/ordermodels";

export const FolderService = {
  async createFolder(model: CreateFolder): Promise<Folder> {
    return await axios.post("/api/app/folder", model);
  },
  async deleteFolder(folderId: string): Promise<Folder> {
    return await axios.delete(`/api/app/marking/folder/${folderId}`);
  },
  async updateFolder(id: string, name: string): Promise<void> {
    return await axios.put("/api/app/order/folder", {id: id, name: name});
  },
};