<template>
    <ul v-if="folder.folders.length">
      <folder-tree-view
        v-for="f in folder.folders"
        :key="f.id"
        :folder="f"
      />
    </ul>
</template>

<script src="./FolderList.ts" lang="ts"></script>
