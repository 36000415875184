import axios from "./index";
import { CurtainParameter } from "@/models/configuratormodels";
import { CalculateCurtainDropsApiOutput, CalculateCurtainDropsApiInput } from "@/models/configuratormodels";

export const ConfiguratorService = {
  async getParameters(): Promise<CurtainParameter> {
    return await axios.get("/api/app/curtaincalculator/parameters");
  },
  async calculateCurtainDrops(input: CalculateCurtainDropsApiInput): Promise<CalculateCurtainDropsApiOutput> {
    return await axios.post("/api/app/curtaincalculator/calculate", input);
  }
};

