import { RouteParams as VueRouterRouteParams } from "vue-router";

export class RouteParams {
    private params: Record<string, string | string[]>;
    constructor(params: VueRouterRouteParams) {
        this.params = params;
    }
    locale(): string {
        return this.params.locale?.toString();
    }
    order(): string {
        return this.params.order?.toString();
    }
    folder(): string {
        return this.params.folder?.toString();
    }
    marking(): string {
        return this.params.marking?.toString();
    }
    product(): number {
        return Number(this.params.product?.toString());
    }
    variant(): number {
        return Number(this.params.variant?.toString());
    }
    step(): number {
        return Number(this.params.step?.toString());
    }
}
