<template>
  <img :src="url" alt="" />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { ImageService } from "@/axios/imageservice";

export default defineComponent({
  name: "Image",
  props: {
    fileId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const url = computed(() => ImageService.imageBaseUrl + props.fileId);
    return {
      url,
    };
  },
});
</script>
