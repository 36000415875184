import { ContentApi } from "@/axios/ContentApi";

export const ContentService = {
    async getHelp(): Promise<string> {
        try {
            const content = await ContentApi.help();
            return content.html;
        } catch (error) {
            console.error(error);
            return "";
        }
    },
    async getBase64ImageUrl(url: string): Promise<string> {
        try {
            const image = await ContentApi.image(url);
            return `data:${image.contentType};base64,${image.base64}`;
        } catch (error) {
            console.error(error);
            return "";
        }
    }

} as const;
