import { NavigationGuardNext, RouteLocationNormalized, Router } from "vue-router";
import { setI18nLanguage, loadLanguageAsync } from "@/i18n";
import type { I18n, Locale } from "vue-i18n";
import { setLocale as setVeeValidateLocale } from "@vee-validate/i18n";
import { RouteParams } from "./RouteParams";
import { AccountStore } from "@/store/accountstate";
import { OrderStore } from "@/store/orderstate";
import { OrderService } from "@/services/OrderService";
import { UIStore } from "@/store/ui";
import { SupportedLocales } from "./SupportedLocales";

export default function createNavigationGuards(router: Router, i18n: I18n): void {

    router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {

        const locale = new RouteParams(to.params).locale() as Locale;

        // check locale
        if (!SupportedLocales.includes(locale)) {
            next(false);
            return;
        }

        // set i18n language
        setI18nLanguage(i18n, locale);
        setVeeValidateLocale(locale);

        // load locale messages
        return loadLanguageAsync(i18n, locale).then(() => {
            if (!to.meta.allowAnonymous && !AccountStore.currentUser) {
                next({
                    name: "login",
                    params: { locale },
                    query: { redirect: to.fullPath }
                });
            }
            else {
                next();
            }
        });
    });

    router.beforeResolve(async (to, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        const params = new RouteParams(to.params);
        const orderId = params.order();
        const folderId = params.folder();
        const markingId = params.marking();

        if (orderId) {
            let order = OrderStore.order;

            if (!order || order.id !== orderId) {
                UIStore.setLoading(true);
                await OrderService.loadOrder(orderId);
                order = OrderStore.order;
                UIStore.setLoading(false);
            }

            if (folderId) {
                OrderStore.setFolder(order?.folders.get(folderId) ?? null);
            }

            if (markingId) {
                OrderStore.setMarking(order?.markings.get(markingId) ?? null);
            }
        }

        next();
    });

    router.afterEach((to: RouteLocationNormalized) => {
        const params = new RouteParams(to.params);
        const orderId = params.order();
        const folderId = params.folder();
        const markingId = params.marking();

        if (!orderId) {
            OrderStore.setOrder(null);
        }

        if (!folderId) {
            OrderStore.setFolder(null);
        }

        if (!markingId) {
            OrderStore.setMarking(null);
        }
    });
}
