<template>
    <div class="side-img__container" :class="{'side-img--hide-on-mobile-tablet': hideOnMobileAndTablet}">
      <img
        src="../../assets/Glory_Prism_Saint_Svensson_V24_6.jpg" 
        alt=""
      />
    </div>
</template>

<script src="./SideImage.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./SideImage.scss";
</style>
