import { computed, defineComponent } from "vue";
import { Marking, MarkingSummary, Order } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import { MarkingService } from "@/services/MarkingService";
import { useI18n } from "vue-i18n";
import { ConfigurationType } from "@/models/configuratormodels";

export default defineComponent({

    name: "MarkingTile",

    props: {
        marking: {
            type: Object as () => Marking,
            required: true
        }
    },

    setup(props) {
        const translate = useI18n().t;
        const order = computed<Order>(() => OrderStore.order as Order);
        const markingSummary = computed<MarkingSummary>(() => MarkingService.getMarkingSummary(props.marking, order.value, translate));
        const showSimpleConfiguration = computed<boolean>(() => markingSummary.value.curtainConfiguration == ConfigurationType.Simple);
        const showCoverwallConfiguration = computed<boolean>(() => markingSummary.value.curtainConfiguration == ConfigurationType.CoverWall);
        return {
            order,
            markingSummary,
            showSimpleConfiguration,
            showCoverwallConfiguration,
        };
    }
});
