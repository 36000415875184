<template>
  <div class="container-with-side-img">
    <side-image />
    <div class="create-order__outer-container">
      <go-back :title="$t('CreateOrder:GoBackHeader')" route-name="orders" />
      <div class="create-order__inner-container">
        <h1>{{ $t("CreateOrder:Heading") }}</h1>
        <form @submit.prevent="submit" v-if="!success" id="create-order-form">
          <div class="input-fields__container">
            <div class="input__container">
              <label class="input__label--thin">{{
                $t("CreateOrder:OfferNameLabel")
              }}</label>
              <input
                class="input__size--large input__icon input__icon--folder"
                v-model.trim="offerName"
                type="text"
                maxlength="30"
                required
              />
            </div>
            <div class="input__container">
              <label class="input__label--large input__label">{{
                $t("CreateOrder:NoteLabel")
              }}</label>
              <textarea v-model.trim="note"></textarea>
            </div>
            <p v-if="error">{{ $t("CreateOrder:Error") }}</p>
          </div>
        </form>
         
      </div>
       <div class="create-order__submit-container wizard-bottom-bar__container">
            <button
              class="btn__default--primary"
              type="submit"
              form="create-order-form"
              :disabled="!isComplete"
              :class="{ 'btn--disabled': !isComplete }"
            >
              {{ $t("CreateOrder:Button") }}
            </button>
          </div>
    </div>
  </div>
</template>

<script src="./CreateOrder.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./CreateOrder.scss";
</style>
