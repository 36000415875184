<template>
  <div class="marking__container container-with-tree">
    <div class="edit-marking-fabric__container">
      <div class="marking__preview-container">
        <button
          class="btn__round marking__preview-btn edit-marking-fabric__close-btn"
          type="button"
        >
          <router-link
            :to="{
              name: 'order',
              params: { order: order.id },
            }"
          >
            <img src="~@/assets/icons/close.svg" class="custom-svgs" alt="x" />
          </router-link>
        </button>
        <div
          class="edit-marking-fabric__image-container"
          v-if="selectedVariant.largeImage"
        >
          <img :src="selectedVariant.largeImage" alt="" />
        </div>
      </div>
      <div class="marking__content-container edit-marking-fabric__content-container">
        <div class="variant-list">
          <div
            class="variant"
            v-for="variant in variants"
            :key="variant.productId"
            @click="selectedVariant = variant"
            :class="{ 'variant--active': variant === selectedVariant }"
          >
            <img :src="variant.image" :title="variant.name" />
            <div class="variant__data">
              <span
                class="variant__data-color"
                :class="{ 'variant__data-color--active': variant.active }"
                >{{ variant.color }}</span
              >
              <span class="variant__data-width">{{ variant.width }} cm</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="wizard-bottom-bar__container marking__bottom-container edit-marking-fabric__wizard"
    >
      <form @submit.prevent="save">
        <button class="btn__default--primary" type="submit">
          {{ $t("EditMarkingFabric:Save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script src="./EditMarkingFabric.ts" lang="ts"></script>


<style lang="scss" scoped>
@import "./fabricselector/VariantSelector.scss";
@import "./fabricselector/EditMarkingFabric.scss";
@import "./fabricselector/WizardSteps.scss";
</style>
