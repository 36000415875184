<template>
  <curtain
    v-if="renderCurtain"
    :textureUrl="textureUrl"
    :curtainWidth="data.width"
    :curtainHeight="data.height"
    :textureSize="10"
    :sewingType="data.sewingType"
    :splitCurtain="data.splitCurtain"
  />
</template>

<script src="./CurtainVisualizer.ts" lang="ts"></script>
