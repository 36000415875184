import { Options, Vue } from "vue-class-component";
import { OrderStore } from "@/store/orderstate";
import { Order, OrderStatus } from "@/models/ordermodels";
import FolderList from "./FolderList.vue";
import FolderTreeView from "./FolderTreeView.vue";
import FolderContextMenu from "./FolderContextMenu.vue";
import MarkingContextMenu from "./MarkingContextMenu.vue";
import GoBack from '@/components/goBack/GoBack.vue';
import { Meta } from "@/router";
import FabricTreeView from "./FabricTreeView.vue";
import { ChangeStore } from "@/store/changestate";
import { OrderService } from "@/services/OrderService";
import Swal from "sweetalert2";

@Options({
    components: {
        FolderList,
        FolderTreeView,
        FolderContextMenu,
        MarkingContextMenu,
        GoBack,
        FabricTreeView
    }
})
export default class OrderSidebar extends Vue {

    selectedItem: MenuItemType = MenuItemType.Unknown;
    menuItems!: MenuItem[];
    showOverlay: boolean = false;
    get editMode(): boolean { return ChangeStore.editMode; };
    get disabledEditMode(): boolean {
      return (ChangeStore.markings.length === 0 && ChangeStore.folders.length == 0) || (ChangeStore.markings.length > 0 && ChangeStore.folders.length > 0) || ChangeStore.markings.length > 1 || ChangeStore.folders.length > 1
    };

    get disabledDeleteBtn(): boolean {
      return (ChangeStore.markings.length === 0 && ChangeStore.folders.length == 0);
    };

    created():  void {
        const metaSideBar = (this.$route.meta as Meta).orderSideBar;
        if (metaSideBar != null && metaSideBar !== MenuItemType.Unknown) {
            this.selectedItem = metaSideBar;
        }

        this.menuItems = [
            { type: MenuItemType.Markings, text: this.$t("MarkingMenu:Markings") },
            { type: MenuItemType.Fabrics, text: this.$t("MarkingMenu:Fabrics") },
            { type: MenuItemType.Images, text: this.$t("MarkingMenu:Images") },
        ];
    }

    get hideOnMobile(): boolean {
        return !!this.$route.meta.hideSideBar;
    }

    get menuItemType(): typeof MenuItemType {
        return MenuItemType;
    }

    get order(): Order {
        const order = OrderStore.order;
        if (order == null) {
            throw "Order not found";
        }
        return order;
    }

    get getPreviewOrderRouteName(): string {
        return this.order.status === OrderStatus.Initiated
            ? "previewOrderInit"
            : "previewOrderFinished";
    }

    get orderPreviewIsEnabled(): boolean {
        return this.order.markings.size > 0;
    }

    goBack(): void {
      ChangeStore.clearState();
    }

    select(item: MenuItemType): void {
        this.selectedItem = item;
    }

    isSelected(item: MenuItemType): boolean {
        return this.selectedItem == item;
    }

    handleContextClick(value: boolean): void {
        this.showOverlay = value;
    }

    toggleEdit(): void {
      ChangeStore.saveEditMode(!ChangeStore.editMode);
    }

    async duplicateSelected(): Promise<void> {
      if (this.disabledEditMode) {
        return;
      }

      if (ChangeStore.markings.length === 1) {
        const response = await Swal.fire<Promise<string>>({
          title: this.$t("OrderSideBar:DuplicateMarking"),
          input: "text",
          inputLabel: this.$t("OrderSideBar:NewName"),
          inputValue: ChangeStore.markings[0].name + " " + this.$t("OrderSideBar:CopyTitle"),
          inputValidator: (value): string | null => {
            if (!value) {
              return this.$t("OrderSideBar:NewNameNotEmpty");
            } else if(value.length > 20) {
              return this.$t("OrderSideBar:LongerThanRequired");
            }
            return null;
          },
          showCancelButton: true,
          focusCancel: true,
          showLoaderOnConfirm: true,
          confirmButtonText: this.$t("OrderSideBar:ConfirmButton"),
          confirmButtonAriaLabel: this.$t("OrderSideBar:ConfirmButton"),
          cancelButtonText: this.$t("OrderSideBar:CancelButton"),
          cancelButtonAriaLabel: this.$t("OrderSideBar:CancelButton"),
          allowOutsideClick: () => !Swal.isLoading(),
          preConfirm: (name: string) => {
            return OrderService.createCopyOfMarking({ id: ChangeStore.markings[0].id, name: name }, this.order.id).then(value => value);
          }
        });
        if (response.isConfirmed) {
          await ChangeStore.clearState();
          this.$router.push({ name: "order", params: { order: this.order.id }});
        }
      }

      if (ChangeStore.folders.length === 1) {
        const response = await Swal.fire<Promise<string>>({
          title: this.$t("OrderSideBar:DuplicateFolder"),
          input: "text",
          inputLabel: this.$t("OrderSideBar:NewName"),
          inputValue: ChangeStore.folders[0].name + " " + this.$t("OrderSideBar:CopyTitle"),
          inputValidator: (value): string | null => {
            if (!value) {
              return this.$t("OrderSideBar:NewNameNotEmpty");
            } else if(value.length > 20) {
              return this.$t("OrderSideBar:LongerThanRequired");
            }
            return null;
          },
          inputAttributes: {
            maxlength: "20"
          },
          showCancelButton: true,
          focusCancel: true,
          showLoaderOnConfirm: true,
          confirmButtonText: this.$t("OrderSideBar:ConfirmButton"),
          confirmButtonAriaLabel: this.$t("OrderSideBar:ConfirmButton"),
          cancelButtonText: this.$t("OrderSideBar:CancelButton"),
          cancelButtonAriaLabel: this.$t("OrderSideBar:CancelButton"),
          allowOutsideClick: () => !Swal.isLoading(),
          preConfirm: (name: string) => {
            return OrderService.createCopyOfFolder({ id: ChangeStore.folders[0].id, name: name }, this.order.id).then(value => value);
          }
        });
        if (response.isConfirmed) {
          await ChangeStore.clearState();
          this.$router.push({ name: "order", params: { order: this.order.id }});
        }
      }
    }

    async deleteSelected(): Promise<void> {
      const response = await Swal.fire<Promise<void>>({
        title: this.$t("OrderSideBar:DeleteSelectedTitle"),
        html: this.$t("OrderSideBar:DeleteSelectedBody"),
        showCancelButton: true,
        focusCancel: true,
        showLoaderOnConfirm: true,
        confirmButtonText: this.$t("OrderSideBar:ConfirmButton"),
        confirmButtonAriaLabel: this.$t("OrderSideBar:ConfirmButton"),
        cancelButtonText: this.$t("OrderSideBar:CancelButton"),
        cancelButtonAriaLabel: this.$t("OrderSideBar:CancelButton"),
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
          return OrderService.deleteFoldersAndMarkings(ChangeStore.folders, ChangeStore.markings, this.order.id);
        }
      });
      if (response.isConfirmed) {
        await ChangeStore.clearState();
        this.$router.push({ name: "order", params: { order: this.order.id }});
      }
    }

    async changeSelected(): Promise<void> {
      if(this.disabledEditMode) {
        return;
      }

      if (ChangeStore.markings.length === 1) {
        const response = await Swal.fire<Promise<string>>({
          title: this.$t("OrderSideBar:RenameSelectedTitle"),
          input: "text",
          inputLabel: this.$t("OrderSideBar:NewName"),
          inputValidator: (value): string | null => {
            if (!value) {
              return this.$t("OrderSideBar:NewNameNotEmpty");
            } else if(value.length > 20) {
              return this.$t("OrderSideBar:LongerThanRequired");
            }
            return null;
          },
          inputValue: ChangeStore.markings[0].name,
          inputAttributes: {
            maxlength: "20"
          },
          showCancelButton: true,
          focusCancel: true,
          showLoaderOnConfirm: true,
          confirmButtonText: this.$t("OrderSideBar:ConfirmButton"),
          confirmButtonAriaLabel: this.$t("OrderSideBar:ConfirmButton"),
          cancelButtonText: this.$t("OrderSideBar:CancelButton"),
          cancelButtonAriaLabel: this.$t("OrderSideBar:CancelButton"),
          allowOutsideClick: () => !Swal.isLoading(),
          preConfirm: (name: string) => {
            return OrderService.updateMarkingNameAndLoadOrder(ChangeStore.markings[0].id, name, this.order.id).then(value => value);
          }
        });
        if (response.isConfirmed) {
          ChangeStore.clearState();
          this.$router.push({ name: "order", params: { order: this.order.id } });
        }
      }

      if (ChangeStore.folders.length === 1) {
        const response = await Swal.fire<Promise<string>>({
          title: this.$t("OrderSideBar:RenameSelectedTitle"),
          input: "text",
          inputLabel: this.$t("OrderSideBar:NewName"),
          inputValidator: (value): string | null => {
            if (!value) {
              return this.$t("OrderSideBar:NewNameNotEmpty");
            } else if(value.length > 20) {
              return this.$t("OrderSideBar:LongerThanRequired");
            }
            return null;
          },
          inputValue: ChangeStore.folders[0].name,
          inputAttributes: {
            maxlength: "20"
          },
          showCancelButton: true,
          focusCancel: true,
          showLoaderOnConfirm: true,
          confirmButtonText: this.$t("OrderSideBar:ConfirmButton"),
          confirmButtonAriaLabel: this.$t("OrderSideBar:ConfirmButton"),
          cancelButtonText: this.$t("OrderSideBar:CancelButton"),
          cancelButtonAriaLabel: this.$t("OrderSideBar:CancelButton"),
          allowOutsideClick: () => !Swal.isLoading(),
          preConfirm: (name: string) => {
            return OrderService.updateFolderNameAndLoadOrder(ChangeStore.folders[0].id, name, this.order.id).then(value => value);
          }
        });
        if (response.isConfirmed) {
          ChangeStore.clearState();
          this.$router.push({ name: "order", params: { order: this.order.id } });
        }
      }
    }
};

export enum MenuItemType {
    Unknown,
    Fabrics,
    Images,
    Markings,
}

interface MenuItem {
    type: MenuItemType,
    text: string,
}
