<template>
  <div class="edit-image__button-container">
    <button class="btn__round" @click="close()">
      <img src="~@/assets/icons/close.svg" class="custom-svgs" />
    </button>
    <button
      class="btn__round btn__with-icon btn__with-icon--delete"
      @click="deleteImage()"
    ></button>
  </div>

  <div class="edit-image__content">
    <div class="edit-image__image">
      <Image :file-id="draft.fileId" />
    </div>
    <div class="edit-image__text-container">
      <div class="edit-image__image-info" v-if="!editing">
        <div class="edit-image__name-container">
          <h2 class="edit-image__name">{{ draft.name }}</h2>
          <button
            class="btn__round btn__with-icon btn__with-icon--pen edit-image__edit-button"
            @click="edit()"
          ></button>
        </div>
        <div class="edit-image__note">{{ draft.note }}</div>
      </div>
      <div class="edit-image__inputs" v-if="editing">
        <div class="input__container">
          <label class="input__label--small input__label">{{
            $t("MarkingImageUpload:ImageNameLabel")
          }}</label>
          <input type="text" v-model="draft.name" />
        </div>
        <div class="input__container">
          <label class="input__label--small input__label">{{
            $t("MarkingImageUpload:ImageNoteLabel")
          }}</label>
          <input type="text" v-model="draft.note" />
        </div>
        <div class="edit-image__inputs-buttons">
          <button
            class="btn__round btn__with-icon btn__with-icon--close"
            @click="cancel()"
          >
            <!-- {{ $t("MarkingImageUpdate:CancelUpdateButtonText") }} -->
          </button>
          <button
            class="btn__round btn__with-icon btn__with-icon--save"
            @click="save()"
          >
            <!-- {{ $t("MarkingImageUpdate:UpdateButtonText") }} -->
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./EditImage.ts" lang="ts"></script>
<style lang="scss" scoped>
@import "EditImage.scss";
</style>
