<template>
  <li class="folder-tree__list" v-if="folder">
    <div class="folder-tree__item folder-tree__root">
      <div class="folder-tree__link preview-order-tree__link">
        <img
          src="../assets/icons/folder.svg"
          class="custom-svgs folder-tree__link-icon"
          alt=""
        />
        <h3 class="folder-tree__heading--big">{{ folder.name }}</h3>
      </div>
    </div>
    <ul class="folder-tree__markings" v-if="folder.markings.length">
      <li v-for="marking in folder.markings" :key="marking.id">
        <preview-order-marking-view :marking="marking" />
      </li>
    </ul>
    <ul v-if="folder.folders.length">
      <preview-order-folder-view
        v-for="f in folder.folders"
        :key="f.id"
        :folder="f"
      />
    </ul>
  </li>
</template>

<script src="./PreviewOrderFolderView.ts" lang="ts"></script>

<style lang="scss" scoped>
  @import "./PreviewOrderTreeView.scss";
</style>
