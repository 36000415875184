<template>
  <div class="content-beside-tree__container container-with-tree edit-order">
    <div class="edit-folder">
      <div class="edit-order__go-back-container">
        <go-back :title="$t('Folder:UpdateFolderHeading')" routeName="order" />
      </div>
      <div class="edit-folder__content">
      <router-link
        :to="{ name: 'order' }"
        class="btn__round btn__with-icon--close btn__with-icon edit-order__close"
      ></router-link>
      <h1 class="edit-order__header">{{ $t("Folder:UpdateFolderHeading") }}</h1>
      <label for="newFoldername" class="input__label edit-order__label">{{
        $t("Folder:UpdateFolderNameTextBoxLabel")
      }}</label>
      <input
        id="newFoldername"
        class="input__size--large input__icon input__icon--folder"
        type="text"
        maxlength="20"
        v-model="folderName"
      />
    </div>
    </div>
      <div
        class="wizard-bottom-bar__container edit-order__wizard-bottom-container"
      >
        <div class="wizard-bottom-bar__right-container edit-folder__right-container--modifications">
          <button
            class="btn__default--primary edit-order__save-btn"
            @click="updateFolderName()"
          >
            {{ $t("Folder:UpdateButtonText") }}
          </button>
        </div>
      </div>
  </div>
</template>

<script src="./EditFolder.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "../orders/EditOrder.scss";
@import "./EditFolder.scss";
</style>
