<template>
  <div class="order__container-outer search-order">
    <div class="order__container-inner content__container">
      <h1>{{ $t("OrderSearch:Label") }}</h1>
      <form @submit.prevent="search" class="search-order__form">
        <input
          v-model="searchQuery"
          class="input__search"
          :placeholder="$t('OrderSearch:Placeholder')"
          @keyup.enter.prevent="$event.target.blur()"
        />
        <button
          type="submit"
          class="btn__round btn__with-icon btn__with-icon--search"
        ></button>
      </form>
      <div class="search-order__table-scroll" v-if="orders.length">
        <table class="search-order__table">
          <thead>
            <tr>
              <th class="list__item search-order__empty-header"></th>
              <th class="list__item">#</th>
              <th class="list__item">{{ $t("SearchOrder:OfferName")}}</th>
              <th class="list__item">{{ $t("SearchOrder:CustomerOrderNumber") }}</th>
              <th class="list__item">{{ $t("SearchOrder:Status") }}</th>
              <th class="list__item">{{ $t("SearchOrder:Email") }}</th>
              <th class="list__item">{{ $t("SearchOrder:OfferSentToCustomerAt") }}</th>
              <th class="list__item"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order.id" @click="loadOrder(order)">
              <td class="list__item search-order__table-icon">
                <svg class="custom-svgs">
                  <use xlink:href="../../assets/icons/sprite.svg#offert"></use>
                </svg>
              </td>
              <td class="list__item">{{ order.offerNumber }}</td>
              <td class="list__item">{{ order.offerName }}</td>
              <td class="list__item">{{ order.customerOrderNumber }}</td>
              <td class="list__item">{{ $t(labels.get(order.status)) }}</td>
              <td class="list__item">{{ order.userEmailAddress }}</td>
              <td class="list__item">{{ $filters.formatDate(order.offerSentToCustomerAt) }}</td>
              <td class="list__item search-order__table-icon">
                <img
                  src="../../assets/icons/chevron-right.svg"
                  class="custom-svgs"
                  alt=""
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="footer__container">
      <div class="footer__invisible-div" />
      <div class="footer__profile-container">
        <router-link :to="{ name: 'account' }">
          <button class="btn__round profile__btn">
            <svg class="svg-icon custom-svgs" viewBox="0 0 20 20">
              <path
                d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"
              ></path>
            </svg>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script src="./SearchOrder.ts" lang="ts"></script>
<style lang="scss" scoped>
  @import "./OrderList.scss";
  @import "./SearchOrder.scss";
</style>
