import axios from "./index";
import { AccountLogin, AccountLoginResponse, AccountPassword,
  AccountForgotOrCreate, AccountForgotResponse,
  PunchoutLogin, AccountConfirm, Token, AccountToken, Secret} from "@/models/accountmodels";

export const AccountService = {
  async signInToken(model: AccountLogin): Promise<AccountLoginResponse> {
    return await axios.post("/api/app/account/signintoken", model);
  },
  async acousticRedirectLink(model: AccountToken): Promise<string> {
    return await axios.post("/api/app/account/acousticRedirectLink", model);
  },
  async signInProtectedLink(model: Secret): Promise<AccountLoginResponse> {
    return await axios.post("/api/app/account/signinsecrettoken",model);
  },
  async punchoutLogin(model: PunchoutLogin): Promise<AccountLoginResponse> {
    return await axios.post("/api/app/account/signinsession", model);
  },
  async refreshToken(model: AccountToken): Promise<Token> {
    return await axios.post("/api/app/account/refreshtoken", model);
  },
  async checkSessionExpired(): Promise<boolean> {
    return await axios.get("/api/app/account/checksessionexpired");
  },
  async signOut(): Promise<void> {
    return await axios.get("/api/account/logout");
  },
  async changePassword(model: AccountPassword): Promise<void> {
    return await axios.post("/api/app/account/changepassword", model);
  },
  async forgotPassword(model: AccountForgotOrCreate): Promise<AccountForgotResponse> {
    return await axios.post("/api/app/account/forgotpassword", model);
  },
  async createAccount(model: AccountForgotOrCreate): Promise<void> {
    return await axios.post("/api/app/account/newaccount", model);
  },
  async confirmEmail(model: AccountConfirm): Promise<void> {
    return await axios.post("/api/app/account/confirmemail", model);
  },
  async setPassword(model: AccountConfirm): Promise<void> {
    return await axios.post("/api/app/account/setpassword", model);
  }
}
