import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Order } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import { OrderService } from "@/services/OrderService";
import { RouteParams } from "@/router/RouteParams";
import { ProductModel } from "@/models/stormmodels";
import { StormService } from "@/axios/stormservice";

export default defineComponent({
    name: "EditMarking",

    async created() {
        this.variants = await StormService.getVariants(this.productId);
        this.selectedVariant = (this.variants.find(x => x.partNo == this.variantId) as ProductModel);
    },

    setup() {
        const route = useRoute();
        const router = useRouter();
        const order = computed(() => OrderStore.order as Order);
        const productId = computed(() => new RouteParams(route.params).product());
        const variantId = computed(() => new RouteParams(route.params).variant());
        const selectedVariant = ref<ProductModel>({} as ProductModel);
        const variants = ref<ProductModel[]>([]);

        async function save() {
            await OrderService.updateMarkingFabric(order.value.id, productId.value, variantId.value, selectedVariant.value.partNo);
            await OrderService.loadOrder(order.value.id);
            router.push({ name: (route.name as string), params: { ...route.params, variant: selectedVariant.value.partNo }});
        }

        return {
            productId,
            variants,
            selectedVariant,
            variantId,
            save,
            order,
        }
    }
});
