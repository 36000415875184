import { computed, defineComponent, ref } from "vue";
import { CopyOrder, CustomerInformation, Order, OrderStatus, PlaceOrder } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import PreviewOrderFolderView from "@/components/PreviewOrderFolderView.vue";
import { useI18n } from "vue-i18n";
import { OrderService } from "@/services/OrderService";
import { AccountStore } from "@/store/accountstate";
import { CurrentUser } from "@/models/accountmodels";
import { useRouter } from "vue-router";
import { UserFriendlyError } from "@/models/errormodels";
import swal from "sweetalert2";
import { GlobalizationService } from "@/services/GlobalizationService";
import { Country } from "@/models/Country";
import GoBack from '@/components/goBack/GoBack.vue'
import { UIStore } from "@/store/ui";

export default defineComponent({
    name: "PreviewOrder",

    components: {
        PreviewOrderFolderView,
        GoBack
    },

    async created() {
        const updateOrder = async (): Promise<Order> => {
            const order = OrderStore.order as Order;
            if (order.status == OrderStatus.Initiated || order.status == OrderStatus.OfferSentToCustomer) {
                return await OrderService.getOrderWithRefreshedPrices(order.id);
            } else {
                return order;
            }
        };
        const [order, countries] = await Promise.all([updateOrder(), GlobalizationService.getCountries()]);
        this.order = order;
        this.countryList = countries;
        UIStore.setLoading(false);
    },

    beforeRouteEnter() {
        UIStore.setLoading(true);
    },

    setup() {
        const router = useRouter();
        const translate = useI18n().t;
        const defaultCountry: string = "SE";
        const order = ref<Order>({} as Order);
        const countryList = ref<Country[]>([]);
        const customerInformation = ref<CustomerInformation>({ country: defaultCountry } as CustomerInformation);
        const showCustomerInformation = ref<boolean>(false);
        const errorMessage = ref<string>();
        const emailSendResponseText = ref<string | null>(null);
        const currentUser = computed<CurrentUser>(() => AccountStore.currentUser as CurrentUser);
        const orderStatusInitiated = computed(() => order.value.status === OrderStatus.Initiated);
        const showChangeOrder = computed(() => order.value.status === OrderStatus.OfferSentToCustomer && !currentUser.value.internalSalesSven);
        const orderStatusSentOrPlaced = computed(() => order.value.status == OrderStatus.OfferSentToCustomer || order.value.status == OrderStatus.OrderPlaced);
        const showPlaceOrderStep1ShowDeliveryAddress = computed(() => currentUser.value.internalSalesSven && order.value.status == OrderStatus.OfferSentToCustomer);
        const showPlaceOrderStep2 = computed(() => currentUser.value.internalSalesSven && order.value.status == OrderStatus.OfferSentToCustomer);
        const showCopyOrder = computed(() => !currentUser.value.internalSalesSven && (order.value.status == OrderStatus.OfferSentToCustomer || order.value.status == OrderStatus.OrderPlaced));
        const showBackButton = computed(() => orderStatusSentOrPlaced);
        const showPlaceOrder = ref<boolean>(false);
        const showOrderTotalDiscountAmount = computed(() => (order.value.orderTotalDiscountAmount.value ?? 0) > 0);
        const showSendOrderPdf = computed(() => order.value.status === OrderStatus.Initiated && !currentUser.value.internalSalesSven);
        const showEmailSendResponse = computed(() =>  !!emailSendResponseText.value )
        const showHideCustomerInformation = (): void => { showCustomerInformation.value = !showCustomerInformation.value; }
        const closeEmailSendResponseBox = (): void => { emailSendResponseText.value = null; }
        const placingOrder = ref<boolean>(false);
        const isComplete = computed(() => !placingOrder.value);
        const showDownloadOrderImages = computed(() => (order.value.folder.markings.flatMap(x=>x.images).length + order.value.folder.folders.flatMap(x=>x.markings).flatMap(m=>m.images).length) > 0);
        const weekNumber = ref<number>();

        async function placeOrder(): Promise<void> {
            placingOrder.value = true;
            const model: PlaceOrder = {
                orderId: order.value.id,
                customerInformation: customerInformation.value
            };
            errorMessage.value = undefined;
            try {
                await OrderService.placeOrder(model);
                await OrderService.loadOrder(order.value.id);
                router.push({ name: "previewOrderComplete", params: { order: order.value.id } });
            } catch (error) {
                const details = error as UserFriendlyError;
                errorMessage.value = details.message;
            }
        }

        async function createOrderCopy(): Promise<void> {
            errorMessage.value = undefined;
            const response = await swal.fire({
                title: translate("PreviewOrder:NameYourCopy"),
                input: "text",
                inputValue: order.value.offerName,
                showCancelButton: true,
                focusCancel: true,
                confirmButtonText: translate("PreviewOrder:ConfirmCopy"),
                cancelButtonText: translate("PreviewOrder:CancelCopy")
            });

            if (response.isConfirmed) {
                try {
                    UIStore.setLoading(true);
                    const request: CopyOrder = {
                        orderId: order.value.id,
                        offerName: response.value as string
                    };
                    const newOrder = await OrderService.createCopyOfOrder(request);
                    if (newOrder.ecomBasketId == null) {
                        errorMessage.value = "Order is too old for copying. Only Orders with order Ids higher than: " + newOrder.offerNumber + " are allowed to be copied"
                    }
                    else {
                        await router.push({ name: "order", params: { order: newOrder.id } });
                    }
                } catch (error) {
                    errorMessage.value = (error as UserFriendlyError).message;
                } finally {
                    UIStore.setLoading(false);
                }
            }
        }

        async function updateWeekNumber(e: InputEvent): Promise<void> {
          const element = (e.target as HTMLInputElement);
          const weekOfYear = await OrderService.getWeekOfYear(new Date(element.value));
          weekNumber.value = weekOfYear;
        }

        async function downloadOrderImages(): Promise<void> {
            try {
                UIStore.setLoading(true);
                const fileName = `Offer-${order.value.offerNumber}-${order.value.offerName}.zip`;
                await OrderService.downloadOrderImages(order.value.id, fileName);
            } catch (error) {
                errorMessage.value = (error as UserFriendlyError).message;
            } finally {
                UIStore.setLoading(false);
            }
        }

        async function sendOrderPdf(): Promise<void> {
            let htmlString = translate("OrderPdf:SendOrderPdfConfirmHtml");
            if (currentUser.value.email.toLowerCase() !== order.value.userEmailAddress.toLowerCase()) {
                htmlString = htmlString.replace("[emailAddress]", order.value.userEmailAddress + ", " + currentUser.value.email)
            } else {
                htmlString = htmlString.replace("[emailAddress]", order.value.userEmailAddress)
            }
            const confirm = await swal.fire({
                title: translate("OrderPdf:SendOrderPdfConfirmTitle"),
                html: htmlString,
                showCancelButton: true,
                focusCancel: true,
                confirmButtonText: translate("OrderPdf:SendOrderPdfConfirmButton"),
                confirmButtonAriaLabel: translate("OrderPdf:SendOrderPdfConfirmButton"),
                cancelButtonText: translate("OrderPdf:SendOrderPdfCancelButton"),
                cancelButtonAriaLabel: translate("OrderPdf:SendOrderPdfCancelButton")
            });
            if (confirm.isConfirmed) {
                UIStore.setLoading(true);
                const response = await OrderService.sendOrderPdf(order.value.id);
                emailSendResponseText.value = translate(response.message);
                router.push({ name: "previewOrderComplete" });
                UIStore.setLoading(false);
            }
        }

        async function changeOrder(): Promise<void> {
            errorMessage.value = undefined;
            try {
                await OrderService.setStatusToInitiated(order.value.id);
                await OrderService.loadOrder(order.value.id);
                router.push({ name: "order", params: { order: order.value.id } });
            } catch (error) {
                const details = error as UserFriendlyError;
                errorMessage.value = details.message;
            }
        }

        return {
            order,
            translate,
            orderStatusInitiated,
            showChangeOrder,
            orderStatusSentOrPlaced,
            showPlaceOrderStep1ShowDeliveryAddress,
            showCopyOrder,
            showBackButton,
            placeOrder,
            currentUser,
            showCustomerInformation,
            showHideCustomerInformation,
            customerInformation,
            showPlaceOrder,
            sendOrderPdf,
            emailSendResponseText,
            showEmailSendResponse,
            closeEmailSendResponseBox,
            errorMessage,
            createOrderCopy,
            countryList,
            showOrderTotalDiscountAmount,
            showSendOrderPdf,
            showPlaceOrderStep2,
            changeOrder,
            isComplete,
            downloadOrderImages,
            showDownloadOrderImages,
            weekNumber,
            updateWeekNumber
        };
    }
});
