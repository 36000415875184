import { createI18n } from "vue-i18n";
import type { I18n, Locale, I18nOptions, Composer } from "vue-i18n";
import { setLocale } from "@vee-validate/i18n";
import axios from "@/axios";
import { Localization } from "@/models/localizationmodels";

const loadedLanguages: string[] = [];

const availableLanguageCodes : Record<string, string> =  {
  "sv": "sv-SE",
  "en": "en-US"
};

export function setI18nLanguage(i18n: I18n, locale: Locale): void {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    ((i18n.global as unknown) as Composer).locale.value = locale;
  }

  axios.defaults.headers.common["Lang"] = availableLanguageCodes[locale];
  (document.querySelector("html") as HTMLElement).setAttribute("lang", locale);
}

export function setupI18n(options: I18nOptions = { locale: "sv" }): I18n {
  const i18n = createI18n(options) as I18n;
  setI18nLanguage(i18n, options.locale as Locale);
  return i18n;
}

async function getSystemTexts(): Promise<Localization> {
  return await axios.get("/api/app/systemtext/", {
    params: {
      "resourceName": "Sven",
    }
  });
}

export async function loadLanguageAsync(i18n: I18n, locale: Locale): Promise<void> {
  if (loadedLanguages.includes(locale)) {
    if (i18n.global.locale !== locale) {
      setI18nLanguage(i18n, locale);
    }
    return Promise.resolve();
  }

  return getSystemTexts().then((response: Localization) => {
    i18n.global.setLocaleMessage(locale, response.texts);
    setI18nLanguage(i18n, locale);
    setLocale(locale);
    loadedLanguages.push(locale)
  });
}