import { getStoreBuilder, BareActionContext } from "vuex-typex";
import { RootState } from "./index";
import { Folder, Marking, Order } from "@/models/ordermodels";

export interface OrderState {
    order: Order | null;
    folder: Folder | null;
    marking: Marking | null;
};

const initialState: OrderState = {
    order: null,
    folder: null,
    marking: null,
};

const store = getStoreBuilder<RootState>().module("order", initialState);

type ActionContext = BareActionContext<OrderState, RootState>;

// getters
const getters = {
    order: (state: OrderState) => state.order,
    folder: (state: OrderState) => state.folder,
    marking: (state: OrderState) => state.marking,
};

const order = store.read(getters.order);
const folder = store.read(getters.folder);
const marking = store.read(getters.marking);

// mutations
const mutations = {
    setOrder: (state: OrderState, order: Order | null): Promise<void> => {
        return new Promise((resolve) => {
            state.order = order;
            resolve();
        });
    },
    setFolder: (state: OrderState, folder: Folder | null): Promise<void> => {
        return new Promise((resolve) => {
            state.folder = folder;
            resolve();
        });
    },
    setMarking: (state: OrderState, marking: Marking | null): Promise<void> => {
        return new Promise((resolve) => {
            state.marking = marking;
            resolve();
        });
    },
};

// actions
async function setOrder(context: ActionContext, order: Order | null) : Promise<void> {
    return await mutations.setOrder(context.state, order);
}

async function setFolder(context: ActionContext, folder: Folder | null) : Promise<void> {
    return await mutations.setFolder(context.state, folder);
}

async function setMarking(context: ActionContext, marking: Marking | null) : Promise<void> {
    return await mutations.setMarking(context.state, marking);
}

// export store
export const OrderStore = {
    get order(): Order | null { return order(); },
    setOrder: store.dispatch(setOrder),
    get folder(): Folder | null { return folder() },
    setFolder: store.dispatch(setFolder),
    get marking(): Marking | null { return marking() },
    setMarking: store.dispatch(setMarking),
};
