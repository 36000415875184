import { Options, Vue } from "vue-class-component";
import { AccountService } from "@/axios/accountservice";
import { AccountLogin, AccountToken, CurrentUser, Token } from "@/models/accountmodels";
import { AccountStore } from "@/store/accountstate";
import SideImage from "@/components/sideImage/SideImage.vue";
import { UserFriendlyError } from "@/models/errormodels";
import { UIStore } from "@/store/ui";
import { GeneralSettingsService } from "@/axios/generalsettingsservice";
import { GeneralSettingsOutputDto } from "@/models/configuratormodels";

@Options({
    components: {
        SideImage
    }
})
export default class Login extends Vue {
    protected emailAddress = "";
    protected password = "";
    protected errorMessage = "";
    protected successMessage = "";
    protected generalSettings: GeneralSettingsOutputDto = {} as GeneralSettingsOutputDto;
    protected showMaintenancePage: boolean = true;

    protected get currentUser(): CurrentUser | null {
        return AccountStore.currentUser;
    }
    protected set currentUser(user: CurrentUser | null) {
        AccountStore.saveUser(user);
    }
    protected get currentToken(): Token | null {
        return AccountStore.currentToken;
    }
    protected set currentToken(token: Token | null) {
        AccountStore.saveToken(token);
    }
    
    async mounted(): Promise<void> {
        await this.checkMaintenanceStatus();
    }

    protected async checkMaintenanceStatus(): Promise<void> {
        try {
            const data = await GeneralSettingsService.getGeneralSettings();
            if (data !== undefined) {
                this.generalSettings = data;
                this.showMaintenancePage = this.generalSettings.showMaintenancePage;
            } 
        }
        catch (error){
            this.errorMessage = (error as UserFriendlyError)?.message;            
        }
    }

    handleLogout(): void {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("currentToken");
    }

    protected async onLogIn(): Promise<void> {
        UIStore.setLoading(true);
        this.errorMessage = "";
        const model: AccountLogin = {
            emailAddress: this.emailAddress,
            password: this.password
        };
               try {
            const data = await AccountService.signInToken(model);
            this.currentUser = data.currentUser;
            this.currentToken = data.token;
            this.password = "";
            const query = this.$route.query;
            
            const accountToken: AccountToken = {
                refreshToken: this.currentToken.refreshToken
            };

            if (data.currentUser.acousticUser && data.currentUser.svenUser || data.currentUser.acousticUser && data.currentUser.internalSalesSven) // If you have access to both Sven and Acoustic
            {
                this.$router.push({ name: "chooseapp" });
            }
            else if (data.currentUser.acousticUser) // If you only have access to Acoustic
            {
                const redirectUrl = await AccountService.acousticRedirectLink(accountToken);
                this.handleLogout();
                window.location.href = redirectUrl
            }
            else // If you only have access to Sven, you will be routed to 'searchorders' if you have internalSalesSven permission otherwise to 'orders'
            {
                if (data.currentUser.internalSalesSven) {
                    this.$router.push({ name: "searchorders" });
                } else {
                    this.$router.push({ name: 'orders' });
                }
            }
        } catch (error) {
            this.errorMessage = (error as UserFriendlyError)?.message;
        } finally {
            UIStore.setLoading(false);
        }
    }
}
