import { computed, defineComponent, ref } from "vue";
import { Marking, Order, OrderLine } from "@/models/ordermodels";
import { OrderStore } from "@/store/orderstate";
import { CurtainOrientationType, SewingType } from "@/models/configuratormodels";
import { useI18n } from "vue-i18n";

export default defineComponent({

    name: "PreviewOrderMarkingView",

    props: {
        marking: {
            type: Object as () => Marking,
            required: true
        },
    },

    methods: {
        toggleTable(_: MouseEvent, toggle?: boolean) {
            if (toggle === undefined) {
                this.active = !this.active
            } else {
                this.active = toggle;
            }
        },
    },

    setup(props) {

        const translate = useI18n().t;
        const active = ref<boolean>(false);

        if (!OrderStore.order) {
            throw "Order not found";
        }

        const getOrderLine = (): OrderLine | undefined => {
            return order.value.orderLines.find((item) => item.markingId === props.marking.id);
        }

        const getSewingTypeName = (type: SewingType): string => {
            return translate("ConfiguratorEditor:SewingType" + SewingType[type] + "Label");
        }

        const getOrientationName = (type: CurtainOrientationType): string => {
            return translate("ConfiguratorEditor:Orientation" + CurtainOrientationType[type] + "Label");
        }

        const getUnderHemName = (value: number): string => {
            return value > 0 ? translate("ConfiguratorEditor:underHemCMLabel") + (value - 2) + " cm" : "";
        }

        const getItemText = (indexItem: number, quantity: number, firstRowQuantity: number): string => {
            const text = translate("PreviewOrder:CurtainDropsItemPrefix");
            if (indexItem === 0) {
                return quantity === 1
                    ? `${text} 1`
                    : `${text} 1 - ${quantity}`;
            }
            return quantity === 1
                ? `${text} ${firstRowQuantity + 1}`
                : "";
        }
        const order = ref<Order>(OrderStore.order);
        const orderLine = ref<OrderLine | undefined>(getOrderLine());
        const markingImage = computed(() => {
            return order.value.fabricMarkingList.find(x => x.markings.find(x => x.markingId === orderLine.value?.markingId))?.variantImage;
        });

        return {
            order,
            orderLine,
            getSewingTypeName,
            getOrientationName,
            getUnderHemName,
            translate,
            active,
            getItemText,
            markingImage
        };
    }
});
