import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { OrderService } from "@/axios/orderservice";
import { Order, OrderStatus } from "@/models/ordermodels";

export default defineComponent({
    name: "SearchOrder",
    props: {
        query: {
            type: String,
            required: false,
        },
    },
    async created() {
        if (this.query !== undefined) {
            this.orders = await OrderService.searchOrders(this.query);
        }
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const translate = useI18n().t;
        const orders = ref<Order[]>([]);
        const searchQuery = ref<string>(props.query ?? "");

        const labels: Map<OrderStatus, string> = new Map([
            [OrderStatus.Initiated, translate("Offers:OpenOffer")],
            [OrderStatus.OfferSentToCustomer, translate("Offers:SentToCustomerOffer")],
            [OrderStatus.OrderPlaced, translate("Offers:ClosedOffer")],
        ]);

        function loadOrder(order: Order) : void {
            const routeName = order.status === OrderStatus.Initiated
                ? "order"
                : "previewOrderFinished";
            router.push({name: routeName, params: { order: order.id}});
        }

        async function search(): Promise<void> {
            searchQuery.value = searchQuery.value.trim();
            router.push({ ...route, query: { "q": searchQuery.value }});
            orders.value = await OrderService.searchOrders(searchQuery.value);
        }

        return {
            labels,
            orders,
            searchQuery,
            search,
            loadOrder
        }
    }
});
