import { computed, defineComponent } from "vue";
import { Folder } from "@/models/ordermodels";
import { useI18n } from "vue-i18n";

export default defineComponent({

    name: "FolderTile",

    props: {
        folder: {
            type: Object as () => Folder,
            required: true
        }
    },

    setup(props) {
        const translate = useI18n().t;
        const folderSize = computed(() => {
            if (props.folder.markings.length > 0) {
                return `${props.folder.markings.length} ${translate("FolderTile:FolderObjects")}`;
            } else {
                return translate("FolderTile:EmptyFolder");
            }
        });

        return {
            folderSize
        };
    }
});
