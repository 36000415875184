import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2";
import SideImage from '@/components/sideImage/SideImage.vue';
import GoBack from '@/components/goBack/GoBack.vue';
import { UserFriendlyError } from "@/models/errormodels";
import { UserOrderSharingApi } from "@/axios/UserOrderSharingApi";

export default defineComponent({

    name: "UserOrderSharing",
    components: {
        SideImage,
        GoBack
    },
    async created() {
        this.sharedEmails = await UserOrderSharingApi.get();
    },
    setup() {
        const translate = useI18n().t;
        const message = ref<string>();
        const email = ref<string>();
        const sharedEmails = ref<string[]>();

        async function addUserOrderSharing() {
            try {
                message.value = "";
                if(!email.value) return;
                await UserOrderSharingApi.add(email.value);
                sharedEmails.value = await UserOrderSharingApi.get();
                email.value = "";
            } catch (error) {
                message.value = (error as UserFriendlyError).message
            }
        };
        async function deleteUserOrderSharing(emailToDelete: string) {
            try {
                message.value = "";
                const response = await Swal.fire({
                    title: translate("UserOrderSharing:DeleteConfirmTitle"),
                    html: translate("UserOrderSharing:DeleteConfirmBody").replace("[emailAddress]", emailToDelete),
                    showCancelButton: true,
                    focusCancel: true,
                    confirmButtonText: translate("UserOrderSharing:DeleteConfirmButtonText"),
                    confirmButtonAriaLabel: translate("UserOrderSharing:DeleteConfirmButtonText"),
                    cancelButtonText: translate("UserOrderSharing:DeleteCancelButtonText"),
                    cancelButtonAriaLabel: translate("UserOrderSharing:DeleteCancelButtonText")
                });
                if (response.isConfirmed) {
                    await UserOrderSharingApi.delete(emailToDelete);
                    sharedEmails.value = await UserOrderSharingApi.get();
                    email.value = "";
                }
            } catch (error) {
                message.value = (error as UserFriendlyError).message
            }
        };

        return {
            email,
            message,
            sharedEmails,
            addUserOrderSharing,
            deleteUserOrderSharing
        }
    }
});
