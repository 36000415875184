import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router"
import { OrderStore } from "@/store/orderstate";
import { Order, Folder } from "@/models/ordermodels";
import { OrderService } from "@/services/OrderService";
import { RouteParams } from "@/router/RouteParams";
import GoBack from '@/components/goBack/GoBack.vue'

const EditFolder = defineComponent({

    name: "EditFolder",

    components: {
        GoBack
    },

    setup(){
        const route = useRoute();
        const router = useRouter();
        const folderName = ref("");
        const getFolder = (id: string): Folder => {
            const folder = order.value.folders.get(id);
            if (!folder) {
                throw `Folder ${id} not found`;
            }
            folderName.value = folder.name;
            return folder;
        };
        const order = computed<Order>(() => OrderStore.order as Order);
        const folder = ref<Folder>(getFolder(new RouteParams(route.params).folder()));

        async function updateFolderName() {
            await OrderService.updateFolder(folder.value.id, folderName.value);

            let updateOrder = false;
            order.value.orderLines.forEach(orderLine => {
                if (folder.value.markings.some(y => y.id === orderLine.markingId)) {
                    orderLine.folderName = folderName.value;
                    updateOrder = true;
                }
            });

            if (updateOrder) {
                await OrderService.updateOrder(order.value);
            }

            await OrderService.loadOrder(order.value.id);
            router.push({ name: "folderview", params: { order: order.value.id, folder: folder.value.id } });
        };

        return {
            order,
            folder,
            folderName,
            updateFolderName
        }
    }
});

export default EditFolder;