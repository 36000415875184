import { GlobalizationApi } from "@/axios/GlobalizationApi";
import {  Country } from "@/models/Country";

export const GlobalizationService = {
    async getCountries(): Promise<Country[]> {
      const countries = await GlobalizationApi.getCountries();
      return Object.entries(countries).map(([name, code]) => {
          return { code, name } as Country
      });
    }
} as const;
