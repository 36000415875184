import { createApp } from "vue";
import App from "./App.vue";
import { setupRouter } from "./router";
import { store } from "./store";
import { setupI18n } from "./i18n";
import { Field, Form, ErrorMessage, configure as configureVeeValidate, defineRule } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import sv from "@vee-validate/i18n/dist/locale/sv.json";
import en from "@vee-validate/i18n/dist/locale/en.json";
import * as rules from "@vee-validate/rules";
import { Vue } from "vue-class-component"
import "./styles/main.scss";
import "sweetalert2/dist/sweetalert2.min.css";
import { SupportedLocales } from "./router/SupportedLocales";
import moment from "moment";

Vue.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate"
]);

Object.keys(rules).forEach(rule => {
    // eslint-disable-next-line
    defineRule(rule, (rules as any)[rule]);
});

/* eslint-disable no-useless-escape */
let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
if (!SupportedLocales.includes(locale)) {
    locale = SupportedLocales[0];
}
const i18n = setupI18n({
    globalInjection: true,
    legacy: false,
    locale: locale,
    fallbackLocale: "sv"
});

moment.locale(locale);

// https://vee-validate.logaretm.com/v4/guide/i18n#overview
configureVeeValidate({
    generateMessage: localize({
        sv, en
    })
});

const router = setupRouter(i18n);
window.$router = router;

const app = createApp(App);
app.use(i18n)
    .use(store)
    .use(router)
    .component("Field", Field)
    .component("Form", Form)
    .component("ErrorMessage", ErrorMessage)
    .mount("#app")

app.config.globalProperties.$filters = {
    truncateDecimals (value : number): number {
      return Math.trunc(value);
    },
    roundDecimals (value : number): number {
      return Math.round(value);
    },
    formatDate(dateTime: string): string {
      if (dateTime !== null) {
        return moment(dateTime).format("YYYY-MM-DD hh:mm")
      }
      return "";
    }
}