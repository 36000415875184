import Axios, { AxiosError, AxiosResponse } from "axios";
import { AccountStore } from "@/store/accountstate";
import { UserFriendlyError, Problem } from "@/models/errormodels";

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
});

axios.interceptors.request.use((config) => {
    const token = AccountStore.currentToken;
    if (token != null) {
      config.headers["Authorization"] = `${token.tokenType} ${token.accessToken}`
    }
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    config.withCredentials = true;
    return config;
  }, (error) => {
    Promise.reject(error)
  }
);

axios.interceptors.response.use((response: AxiosResponse) => {
  return response.data;
}, (error: AxiosError<Problem>) => {
  if (error.response) {
    if (error.response.status === 401) {
      AccountStore.clearState();
      window.$router.push({name: "login"});
      return;
    }
    const details: UserFriendlyError = {
      status: error.response.status,
      message: error.response.data.error.message
    };
    return Promise.reject(details);

  } else if (error.request) {
    // TODO: What to do when there is no response
    const details: UserFriendlyError = {
      status: 500,
      message: "look in console"
    };
    console.error(error.request);
    return Promise.reject(details);
  }

  const details: UserFriendlyError = {
    status: 500,
    message: error.message
  };
  return Promise.reject(details);
});

export default axios;
