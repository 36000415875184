import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router"
import { OrderStore } from "@/store/orderstate";
import { Order, Folder } from "@/models/ordermodels";
import { OrderService } from "@/services/OrderService";
import { RouteParams } from "@/router/RouteParams";

export default defineComponent({

    name: "Folder",

    setup() {
        const route = useRoute();
        const router = useRouter();
        const getFolder = (id: string): Folder => {
            const folder = order.value.folders.get(id);
            if (!folder) {
                throw `Folder ${id} not found`;
            }
            return folder;
        };
        const order = computed<Order>(() => OrderStore.order as Order);
        const folder = ref<Folder>(getFolder(new RouteParams(route.params).folder()));
        const folderName = ref("");
        const folderSaving = ref<boolean>(false);
        const invalidFolderName = computed<boolean>(() => !folderName.value.trim());
        const submitButtonIsDisabled = computed<boolean>(() => folderSaving.value || invalidFolderName.value);

        async function submit() {
          folderName.value = folderName.value.trim();
          folderSaving.value = true;
          const newFolder = await OrderService.createFolder({
            name: folderName.value,
            parentId: folder.value.id,
            orderId: order.value.id
          });
          await OrderService.loadOrder(order.value.id);
          router.push({ name: "folderview", params: { order: order.value.id, folder: newFolder.id } });
        }

        onBeforeRouteUpdate(async to => {
          folder.value = getFolder(new RouteParams(to.params).folder());
        });

        return {
            order,
            folder,
            folderName,
            submit,
            submitButtonIsDisabled
        }
    }
});
