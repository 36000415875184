<template>
  <div class="container-with-side-img">
    <side-image :hideOnMobileAndTablet="true"/>

    <div class="user-order-share">
      <go-back :title="$t('UserOrderSharing:MenuLabel')" route-name="account" />

      <div class="content__container container-limited--side-img">
        <div class="user-order-share__input-container">
          <div>
            <label class="input__label--small input__label"
              >{{ $t("UserOrderSharing:EmailLabel") }}
            </label>
            <input type="text" v-model="email" />
          </div>
            <button
              class="btn__default--primary"
              type="button"
              @click="addUserOrderSharing()"
            >
              {{ $t("UserOrderSharing:SearchButtonLabel") }}
            </button>
        
        </div>
        <ul v-if="sharedEmails" class="list">
          <li class="list__item user-order-share__list-item" v-for="email in sharedEmails" :key="email" >
            <div class="list__content">
              <svg class="custom-svgs">
                      <use xlink:href="../../assets/icons/sprite.svg#user"></use>
              </svg>
             <span>{{ email.sharedToEmail }} </span>
            </div>
            <img
              src="../../assets/icons/trash.svg"
              alt="delete"
              class="custom-svgs delete-shared-user"
              @click="deleteUserOrderSharing(email.sharedToEmail)"              
            />
          </li>
        </ul>

        <div v-if="message" class="user-order-share__error">
          {{ $t(message) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./UserOrderSharing.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./UserOrderSharing.scss";
</style>