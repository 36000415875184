<template>
  <div v-if="marking" class="context-menu__container">
    <ul v-if="active" class="context-menu__options">
      <li class="context-menu__option">
        <span @click="toggle(false)">
          <router-link :to="{ name: 'create-marking-image', params: { order: order.id, marking: marking.id }}" class="context-menu__option-link">
              <img
                src="../assets/icons/marking.svg"
                class="custom-svgs folder-tree__link-icon context-menu__icon"
                alt=""
              />
              {{$t("ContextMenu:Photo")}}
          </router-link>
        </span>
      </li>
    </ul>
    <button
      class="context-menu__button btn__round"
      @click="toggle"
      :class="{ 'context-menu__button--active': active }"
    >
       <svg class="custom-svgs">
          <use xlink:href="../assets/icons/sprite.svg#add"></use>
      </svg>
    </button>
  </div>
</template>

<script src="./MarkingContextMenu.ts" lang="ts"></script>

<style lang="scss" scoped>
  @import "./ContextMenu.scss";
</style>
