import { watch, defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { RouteParams } from "./router/RouteParams";
import { AccountStore } from "./store/accountstate";
import { AccountService } from "./axios/accountservice";
import Backdrop from "@/components/Backdrop.vue";

export default defineComponent({
    name: "App",

    components: {
        Backdrop,
    },

    setup() {
        const { locale } = useI18n({ useScope: "global" });
        const router = useRouter();
        const route = useRoute();
        const currentLocale = ref<string>(locale.value);
        const orderId = computed(() => new RouteParams(route.params).order());
        const checkLoggedIn = setInterval(async () => {
            if (AccountStore.currentToken != null) {
                try {
                    const expired = await AccountService.checkSessionExpired()
                    if (expired) {
                        throw "Session has expired";
                    }
                } catch (error) {
                    AccountStore.clearState();
                    clearInterval(checkLoggedIn);
                    router.push({ name: "login", query: { redirect: route.fullPath } });
                }
            }
        }, 60000);

        window.addEventListener("beforeunload", () => {
            if (checkLoggedIn) {
                clearInterval(checkLoggedIn);
            }
        });

        // sync to switch locale from router locale path
        watch(router.currentRoute, route => {
            currentLocale.value = new RouteParams(route.params).locale();
        });

        // when change the locale, go to locale route
        watch(currentLocale, val => {
            router.push({
                name: router.currentRoute.value.name ?? "home",
                params: { locale: val }
            });
        });

        return {
            orderId,
        };
    }
});
